import React from 'react'
import style from '../GosKey/yourcenter.module.css'
import styles from './esia.module.css'
import logo from '../../images/confirmation.svg'
import navstyle from '../../components/navbar/navbar.module.css'
import { Link } from 'react-router-dom'
export default function EsiaInstruction() {
  return (
    <>
    <div className={navstyle.navbar}>
    <div className={navstyle.navbar_item_adaptive}>
        <span>1</span>
      </div>
    <div className={navstyle.navbar_dots_adaptive}>
      <div className={navstyle.navbar_dots_item}></div>
      <div className={navstyle.navbar_dots_item}></div>
      <div className={navstyle.navbar_dots_item}></div>
    </div>
    
    <div className={navstyle.navbar_item_adaptive}>
        <span>2</span>
      </div>
      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>


      <div className={navstyle.navbar_item_adaptive}>
        <span>3</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={navstyle.navbar_item_adaptive}>
        <span>4</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={ navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>
      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={ navstyle.navbar_item}>
          <span>6</span>
        </div>

      <div className={navstyle.navbar_dots}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={  navstyle.item_wrapper_active}>
        <div className={ navstyle.navbar_item_active }>
          <span>7</span>
        </div>
        <div className={ navstyle.navbar_active_section  }>
          <p className={ navstyle.active_section_text} >Установка приложения</p>
        </div>
        </div>
    
        </div>
    <div className={style.container}>
                <div className={style.title_wrapper}>
                    <img className={style.title_img} src={logo} alt="" />
                    <h1 className={style.title}>РЕГИСТРАЦИЯ ИЛИ ПОДТВЕРЖДЕНИЕ УЧЕТНОЙ ЗАПИСИ В ЕСИА</h1>
                </div>
                <div className={styles.content}>
                    <ul className={styles.list}>
                        <li className={styles.list_item}>Войдите в мобильное приложение вашего банка</li>
                        <li className={styles.list_item}>В поиске введите "Госуслуги"</li>
                        <li className={styles.list_item}>Следуйте инструкции в мобильном приложении</li>
                        </ul>
                        <a className={styles.link} target='_blank' href="https://www.gosuslugi.ru/help/faq/login/2">Подробная инструкция на сайте Госуслуги</a>

                </div>
                <div className={styles.link_wrapper}>
                    <Link className={style.link} to='/confirmation'>Назад</Link>
                </div>
               
    </div>
    </>
  )
}
