import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import style from './citizenPassport.module.css'
import navstyle from '../../components/navbar/navbar.module.css'
import passportImg from '../../images/ruPassport.svg'
import upload from '../../images/upload.svg'
import urls from '../../urls.json'
import ModalWindow from '../../components/modal/ModalWindow';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const axios = require('axios').default;


export default function СitizenPassportUpload() {
  useEffect(() => {
    GetPassport()
  }, [])
  const [isFirst, setFirst] = useState(false);
  const [isLoader, setLoader] = useState(false)
  const [base64, setBase64] = useState(null)
  const [firstPhoto, setFirstPhoto] = useState(null)
  const [modalText, setModalText] = useState('ЗАГРУЗКА ДАННЫХ НЕ УДАЛАСЬ ПО ПРИЧИНЕ ТАКОЙ-ТО')
  const [modal, setModal] = useState(false)
  async function GetPassport() {
    setLoader(true)
    await axios.get(`${urls.url}/api/user/passport?id=${localStorage.getItem("id")}`).then(response => {
      setBase64(response.data)
      setFirst(true)
    }).catch(error => setLoader(false))
    setLoader(false)
  }

  async function First() {
    const formData = new FormData();
    formData.append("file", firstPhoto)
    formData.append("id", localStorage.getItem("id"))
    setLoader(true)
    await axios({
      method: "post",
      url: `${urls.url}/api/idx/first`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    }).then(response => {
      setBase64(response.data.base64)
      setModalText("Паспорт загружен")
      setModal(true)
      setFirst(true)
    })
      .catch(error => {
        setModalText("Ошибка загрузки паспорта")
        setModal(true)
        setFirst(false)
      })
    setLoader(false)

  }
  useEffect(() => {
    if(firstPhoto!=null){
      First()
    }
  }, [firstPhoto])

  return (
    <> <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
      <ModalWindow isActive={modal} text={modalText} onClose={() => setModal(false)} />
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>2</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Ввод паспортных данных</p>
          </div>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>6</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>
      <div className={style.container}>
        <div className={style.title_wrapper}>
          <img className={style.img} src={passportImg} alt="" />
          <h1 className={style.title}>Загрузите страницы паспорта для автораспознавания</h1>
        </div>
        <div className={style.inputs_container}>
          <div className={style.input_frame} style={{ backgroundImage: (base64 == null) ? "" : `url('data:image/jpeg;base64, ${base64}')` }}>
            <label htmlFor='1'>Разворот с фото</label>
            {!isFirst &&
              <React.Fragment>
                <img className={style.upload_img} src={upload} alt="" />
                <div className={style.text_wrapper}>
                  <p style={{ color: '#C1D3DC' }}>Сделайте фото или выберите</p>
                  <p style={{ color: '#69CD7C' }}>файлы с устройства</p>
                  <p style={{ color: '#C1D3DC' }}>Сканы или четкие фотографии. Допустимые форматы: jpg, jpeg, png. Размер файла не более 10Мб</p>
                </div>

              </React.Fragment>
            }
            <input accept="image/*" className={style.input} onChange={(e) => setFirstPhoto(e.target.files[0])} type="file" />
          </div>
          {isFirst &&
            <p style={{ color: '#69CD7C' }}>Файл загружен!</p>
          }
        </div>
        <div className={style.buttons_wrapper}>
          <div style={{ pointerEvents: (!isFirst) ? "none" : "all", opacity: (!isFirst) ? .5 : 1 }} className={style.button_wrapper}>
            <Link to='/filldata' className={style.button}>Далее</Link>
          </div>

          <div>
            <Link to="/startform" style={{ marginTop: '20px', backgroundColor: "red" }} className={style.button_handwrite}>Назад</Link>
          </div>
        </div>

      </div>
    </>

  )
}
