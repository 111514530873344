import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import style from './method.module.css'
import navstyle from '../../components/navbar/navbar.module.css'
import methodChoiceImg from '../../images/methodChoice.svg'
import Switcher from '../../components/switch/Switch'
export default function MethodChoice() {
  const [passport, setPassport] = useState(false)
  const [nfc, setNfc] = useState(false)
  const [center, setCenter] = useState(localStorage.getItem("center"))
  return (
    <>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item}>
          <span>5</span>
        </div>
        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>6</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Выбор типа электронной подписи</p>
          </div>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>


      {/* main content */}
      <div className={style.container}>
        <div className={style.title_wrapper}>
          <img src={methodChoiceImg} alt="" />
          <h1 style={{ textTransform: "uppercase" }}>Выпуск Усиленной квалифицированной электронной подписи (УКЭП)</h1>
        </div>

        <div className={style.method_content_wrapper}>
          <div className={style.method_text_wrapper}>
            <p className={style.method_text}>Согласно требованиям ФЗ-63, для выпуска УКЭП необходима обязательная личная идентификация личности. Ее мы проводим в удобном для Вас месте и времени и как правило, уже на следующий день.</p>
          </div>
          <div className={style.method_text_wrapper}>
            <p className={style.method_text}>УКЭП можно оформить прямо сейчас, если у Вас есть действующий заграничный паспорт сроком действия 10 лет (нового образца) и телефон с поддержкой NFC (большинство современных смартфонов имеют эту функцию)</p>
          </div>
          <div className={style.method_swithers_container}>
            <div className={style.switcher_wrapper}>
              <div className={style.switcher}>
                <Switcher value={passport} onClick={() => setPassport(!passport)} />
              </div>
              <span className={style.switcher_text}>Есть заграничный паспорт нового образца</span>
            </div>
            <div className={style.switcher_wrapper}>
              <div className={style.switcher}>
                <Switcher value={nfc} onClick={() => setNfc(!nfc)} />
              </div>
              <span className={style.switcher_text}>Есть телефон с NFC</span>
            </div>
            <div className={style.button_container}>
              {
                (passport && nfc) &&
                <Link to={"/centerLoader/idpoint"}>
                  <button className={style.button}>Далее</button>
                </Link>
              }
              {
                ((!passport || !nfc) && center == "itmonitoring") &&
                <Link to={"/centerLoader/itmonitoring"}>
                  <button className={style.button}>Далее</button>
                </Link>
              }
              {
                ((!passport || !nfc) && center == "signme") &&
                <Link to={"/centerLoader/signme"}>
                  <button className={style.button}>Далее</button>
                </Link>
              }
              <Link to='/editdata' style={{backgroundColor:"red"}} className={style.button_back}>Назад</Link>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
