import React, { useEffect, useState } from 'react'
import style from './userform.module.css'
import { useNavigate, useParams } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import fileImg from '../../images/file.svg'
import pencilImg from '../../images/pencil.svg'
import { Link } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import navstyle from '../../components/navbar/navbar.module.css'
import urls from '../../urls.json'
import ModalWindow from '../../components/modal/ModalWindow';
import axios from 'axios';
import { Backdrop, CircularProgress } from '@mui/material';
export default function EditData() {
  const id = localStorage.getItem("id")
  const [regionCode, setRegionCode] = useState([])
  const [adressOptions, setAdressOptions] = useState([])
  const [issuedByOptions, setIssuedByOptions] = useState([])
  const [modalText, setModalText] = useState('ЗАГРУЗКА ДАННЫХ НЕ УДАЛАСЬ ПО ПРИЧИНЕ ТАКОЙ-ТО')
  const [modal, setModal] = useState(false)
  const [center, setCenter] = useState(null)
  const [selectGender, setSelectGender] = useState(false)
  const [selectBirthPlace, setSelectBirhtPlace] = useState(false)
  const [selectRegAdress, setSelectRegAdress] = useState(false)
  const [selectIssuedBy, setSelectIssuedBy] = useState(false)
  const [placeOfBirthOptions, setPlaceOfBirthOptions] = useState([])
  const [isLoader, setLoader] = useState(true)
  const [errors, setErrors] = useState({})

  const selectItems = [
    'Сергеевич',
    'Пушкин',
    'Александр',
  ]
  const [inputValues, setInputValue] = useState({
    surname: "",
    name: "",
    email: "",
    fathersname: "",
    phone: "",
    birthday: "",
    gender: "",
    placeOfBirth: "",
    issuedBy: "",
    dateOfIssue: "",
    series: "",
    number: "",
    subdivision: "",
    regAddress: "",
    snils: "",
    inn: "",
    country: "",
    city: "",
    citizenship: ""
  })
  useEffect(() => {
    axios.get(`${urls.url}/api/user?id=${id}`)
      .then(response => {
        const data = response.data
        setInputValue({
          surname: data.surname,
          name: data.name,
          fathersname: data.patronymic,
          phone: data.phone,
          email: data.email,
          birthday: data.birthdate,
          regAddress: data.regAddress,
          issuedBy: data.subDivisionAddress,
          birthday: data.birthDate,
          birthPlace: data.birthPlace,
          snils: data.snils,
          inn: data.inn,
          series: data.serial,
          number: data.number,
          dateOfIssue: data.issueDate,
          subdivision: data.subDivisionCode,
          placeOfBirth: data.birthPlace,
          citizenship: data.citizenship,
          gender: data.gender == 1 ? "МУЖ" : "ЖЕН",
        })
        setCenter(data.center)
        setLoader(false)
      }).catch(error => { setModal(true); setModalText("Не удалось получить данные") })
  }, [])
  useEffect(() => {
    const source = axios.CancelToken.source();
    (async () => {
      await axios.get(`${urls.url}/api/dadata/address?query=${inputValues.regAddress}`, { cancelToken: source.token })
        .then(function (response) {
          setAdressOptions(response.data)
          setRegionCode(response.data.map((x) => (x.cladr)))
          if (response.data.length == 0) {
            setAdressOptions([{ value: 'Ничего не найдено' }])
          }
        }).catch(function (error) {
          setAdressOptions([{ value: 'Ничего не найдено' }])
        })
    })();
    return () => {
      source.cancel("Pervious request canceled");
    };
  }, [inputValues.regAddress])
  useEffect(() => {
    const source = axios.CancelToken.source();
    (async () => {
      await axios.get(`${urls.url}/api/dadata/issuedby?query=${inputValues.issuedBy}`, { cancelToken: source.token })
        .then(function (response) {
          setIssuedByOptions(response.data)
          if (response.data.length == 0) {
            setIssuedByOptions([{ value: 'Ничего не найдено' }])
          }
        }).catch(function (error) {
          setIssuedByOptions([{ value: 'Ничего не найдено' }])

        })
    })();
    return () => {
      source.cancel("Pervious request canceled");
    };

  }, [inputValues.issuedBy])

  function handleChange(event) {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
  }

  function birthPlaceSelect(placeOfBirth) {
    setInputValue({ ...inputValues, placeOfBirth: placeOfBirth })
    setSelectBirhtPlace(false)
  }
  function regAddressSelect(regAddress) {
    setInputValue({ ...inputValues, regAddress: regAddress })
    setSelectRegAdress(false)
  }
  function issuedBySelect(issuedBy) {
    setInputValue({ ...inputValues, issuedBy: issuedBy })
    setSelectIssuedBy(false)
  }
  // ***

  // ***
  function issuedBySelectSettings(issuedBy) {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.issuedBy.length == 1) {
      setSelectIssuedBy(false)
    }
    if (inputValues.issuedBy.length > 1) {
      setSelectIssuedBy(true)

    } else if (inputValues.issuedBy == i) {
      setSelectIssuedBy(false)
    }
  }
  function regAddressSelectSettings(regAddress) {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.regAddress.length == 1) {
      setSelectRegAdress(false)
    }
    if (inputValues.regAddress.length > 1) {
      setSelectRegAdress(true)

    } else if (inputValues.regAddress == i) {
      setSelectRegAdress(false)
    }
  }

  function birthPlaceSelectSettings(placeOfBirth) {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.placeOfBirth.length == 1) {
      setSelectBirhtPlace(false)
    }
    console.count('useEffect count:')
    if (inputValues.placeOfBirth.length > 1) {
      setSelectBirhtPlace(true)

    } else if (inputValues.placeOfBirth == i) {
      setSelectBirhtPlace(false)
    }
  }

  const checkValidation = () => {
    const errors = {};
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^.{12,}$/;
    const [day, month, year] = inputValues.dateOfIssue.split('.')

    if (!inputValues.surname) {
      errors.surname = 'обязательное поле'
    }
    else if (inputValues.surname.length < 3) {
      errors.surname = 'длина менее 3'
    }
    if (!inputValues.phone) {
      errors.phone = 'обязательное поле'
    } else if (inputValues.phone.length < 11) {
      errors.phone = 'неверный формат'
    }
    if (!inputValues.email) {
      errors.email = 'обязательное поле'
    } else if (!regex.test(inputValues.email)) {
      errors.email = 'неверный формат'
    }
    if (!inputValues.name) {
      errors.name = 'обязательное поле'
    } else if (inputValues.name.length < 3) {
      errors.name = 'длина менее 3'
    }
    if (!inputValues.fathersname) {
      errors.fathersname = 'обязательное поле'
    } else if (inputValues.fathersname.length < 3) {
      errors.fathersname = 'длина менее 3'
    }
    if (!inputValues.gender) {
      errors.gender = 'обязательное поле'
    }
    if (!inputValues.birthday) {
      errors.birthday = 'обязательное поле'
    }
    if (inputValues.birthday.length < 10) {
      errors.birthday = 'неверный формат'
    }
    if (day > 31) {
      errors.birthday = 'неверный формат'
    }
    if (month > 12) {
      errors.birthday = 'неверный формат'
    }
    if (year < 1900) {
      errors.birthday = 'неверный формат'
    }
    if (!inputValues.issuedBy) {
      errors.issuedBy = 'обязательное поле'
    }
    if (!inputValues.dateOfIssue) {
      errors.dateOfIssue = 'обязательное поле'
    }
    if (inputValues.dateOfIssue.length < 10) {
      errors.dateOfIssue = 'неверный формат'
    }
    if (!inputValues.placeOfBirth) {
      errors.placeOfBirth = 'обязательное поле'
    }
    if (!inputValues.series) {
      errors.series = 'обязательное поле'
    }
    if (!inputValues.subdivision) {
      errors.subdivision = 'обязательное поле'
    }
    if (!inputValues.number) {
      errors.number = 'обязательное поле'
    }
    if (!inputValues.regAddress) {
      errors.regAddress = 'обязательное поле'
    }
    if (!inputValues.citizenship) {
      errors.citizenship = 'обязательное поле'
    }
    if (!inputValues.snils) {
      errors.snils = 'обязательное поле'
    }
    if (!inputValues.inn) {
      errors.inn = 'обязательное поле'
    }
    if (!inputValues.city) {
      errors.city = 'обязательное поле'
    }

    if (!inputValues.country) {
      errors.country = 'обязательное поле'
    }
    if (inputValues.citizenship.length == 0) {
      errors.citizenship = "обязательное поле"
    }
    if (day > 31) {
      errors.dateOfIssue = 'неверный формат'
    }
    if (month > 12) {
      errors.dateOfIssue = 'неверный формат'
    }
    if (year < 1900) {
      errors.dateOfIssue = 'неверный формат'
    }
    return errors
  }
  // **

  // **
  const body = {
    "id": id,
    "regionCode": regionCode[0],
    "surname": inputValues.surname,
    "name": inputValues.name,
    "patronymic": inputValues.fathersname,
    "serial": inputValues.series,
    "number": inputValues.number,
    "regAddress": inputValues.regAddress,
    "birthDate": inputValues.birthday,
    "birthPlace": inputValues.placeOfBirth,
    "issueDate": inputValues.dateOfIssue,
    "subDivisionCode": inputValues.subdivision,
    "subDivisionAddress": inputValues.issuedBy,
    "gender": inputValues.gender === "МУЖ" ? 1 : 2,
    "phone": inputValues.phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('-', ''),
    "email": inputValues.email,
    "snils": inputValues.snils,
    "inn": inputValues.inn

  }
  const handleSubmit = () => {
    setLoader(true)
    let errors = checkValidation(inputValues)
    setErrors(errors)
    axios.put(`${urls.url}/api/user/update`, body,
      { headers: { "Content-Type": "application/json" } })
      .then(response => {
        if (response.status === 200) {
          window.location.href = `/centerloader/${center}`
        }
        setLoader(false)
      })
  };
  // ******

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalWindow isActive={modal} text={modalText} onClose={() => setModal(false)} />
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>5</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Проверка анкеты</p>
          </div>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>6</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>
      <div className={style.container}>
        <div className={style.title_container}>
          <img className={style.img} src={fileImg} alt="" />
          <div className={style.title_text_wrapper}>
            <h1 className={style.title}>проверьте данные в анкете на выпуск электронной подписи</h1>
            <p className={style.sub_title}>Заявление сформировано. Проверьте, все ли данные указаны правильно. </p>
          </div>
        </div>
        <div className={style.inputs_container}>
          <div className={style.inputs_wrapper}>
            <div className={style.input_wrapper}>
              <label className={style.label}>Фамилия</label>
              <IMaskInput
                onChange={(e) => handleChange(e)}
                name='surname'
                mask='##############################'
                value={inputValues.surname}
                definitions={{ '#': /[а-яА-Я]/, }}
                className={style.input} />
              <p className={style.error_text}>{errors.surname}</p>
            </div>
            <div className={style.input_wrapper}>
              <label className={style.label}>Имя</label>
              <IMaskInput
                mask='###############################'
                definitions={{
                  '#': /[а-яА-Я]/,
                }}
                onChange={(e) => handleChange(e)}
                name='name'
                value={inputValues.name}
                className={style.input} />
              <p className={style.error_text}>{errors.name}</p>
            </div>
            <div className={style.input_wrapper}>
              <label className={style.label}>Отчество</label>
              <IMaskInput
                mask='###############################'
                definitions={{
                  '#': /[а-яА-Я]/,
                }}
                onChange={(e) => handleChange(e)}
                name='fathersname'
                value={inputValues.fathersname}
                className={style.input} />
              <p className={style.error_text}>{errors.fathersname}</p>
            </div>
            <div className={style.input_wrapper}>
              <label className={style.label}>Дата рождения</label>
              <IMaskInput
                mask="00.00.0000"
                onChange={handleChange}
                name='birthday'
                value={inputValues.birthday}
                className={style.input} />
              <p className={style.error_text}>{errors.birthday}</p>
            </div>
            <OutsideClickHandler onOutsideClick={() => setSelectBirhtPlace(false)}>
              <div className={style.input_wrapper}>
                <label className={style.label}>Место рождения</label>
                <textarea
                  onChange={(e) => { handleChange(e); birthPlaceSelectSettings() }}
                  name='placeOfBirth'
                  value={inputValues.placeOfBirth}
                  className={style.input_text}
                  style={{ resize: 'none' }}
                />
                <p className={style.error_text}>{errors.placeOfBirth}</p>
                <div className={selectBirthPlace ? style.custom_select_wrapper :
                  style.custom_select_wrapper_disabled}>
                  <ul className={style.select_list}>
                    {placeOfBirthOptions.map((x, index) => (
                      <li onClick={() => { birthPlaceSelect(x.value); setSelectBirhtPlace(false) }} key={index} className={style.list_item}>{x.value}</li>))}
                  </ul>
                </div>
              </div>
            </OutsideClickHandler>

            <OutsideClickHandler onOutsideClick={() => setSelectGender(false)}>
              <div className={style.input_wrapper}>
                <label className={style.label}>Пол</label>
                <div onClick={() => setSelectGender(true)} className={style.input_gender}>
                  <span style={{ margin: '0 0 10px 0' }}>{inputValues.gender}</span>
                </div>
                <div className={selectGender ? style.custom_select_wrapper :
                  style.custom_select_wrapper_disabled}>
                  <p onClick={() => { setInputValue({ ...inputValues, gender: 'МУЖ' }); setSelectGender(false) }} className={style.list_item} value="Мужской">Мужской</p>
                  <p onClick={() => { setInputValue({ ...inputValues, gender: 'ЖЕН' }); setSelectGender(false) }} className={style.list_item} value="Женский">Женский</p>
                </div>
                <p className={style.error_text}>{errors.gender}</p>
              </div>
            </OutsideClickHandler>

            <div className={style.input_wrapper}>
              <label className={style.label}>СНИЛС</label>
              <IMaskInput
                value={inputValues.snils}
                mask='000-000-000-00'
                onChange={handleChange}
                name='snils'
                className={style.input} />
              <p className={style.error_text}>{errors.snils}</p>
            </div>
            <div className={style.input_wrapper}>
              <label className={style.label}>ИНН</label>
              <IMaskInput
                value={inputValues.inn}
                mask="000000000000"
                maxLength={12}
                onChange={handleChange}
                name='inn'
                className={style.input} />
              <p className={style.error_text}>{errors.inn}</p>
            </div>
            <div className={style.input_wrapper}>
              <label className={style.label}>Серия паспорта</label>
              <IMaskInput
                value={inputValues.series}
                onChange={handleChange}
                mask="00 00"
                name='series'
                className={style.input} />
              <p className={style.error_text}>{errors.series}</p>
            </div>
            <div className={style.input_wrapper}>
              <label className={style.label}>Номер паспорта</label>
              <IMaskInput
                value={inputValues.number}
                mask="000000"
                onChange={(e) => handleChange(e)}
                name='number'
                className={style.input} />
              <p className={style.error_text}>{errors.number}</p>
            </div>
          </div>
          <div className={style.inputs_wrapper}>
            <OutsideClickHandler onOutsideClick={() => setSelectRegAdress(false)}>
              <div className={style.input_wrapper}>
                <label className={style.label}>Адрес (регистрации)</label>
                <textarea
                  onChange={(e) => { handleChange(e); regAddressSelectSettings() }}
                  name='regAddress'
                  style={{ resize: 'none' }}
                  value={inputValues.regAddress}
                  className={style.input_text} />
                <div className={selectRegAdress ? style.custom_select_wrapper :
                  style.custom_select_wrapper_disabled}>
                  <ul className={style.select_list}>
                    {adressOptions.map((x, index) => (
                      <li onClick={() => { regAddressSelect(x.value); setSelectRegAdress(false) }} key={index} className={style.list_item}>{x.value}</li>))}
                  </ul>
                </div>
                <p className={style.error_text}>{errors.regAddress}</p>
              </div>
            </OutsideClickHandler>
            <div className={style.input_wrapper}>
              <label className={style.label}>Контактный телефон</label>
              <IMaskInput
                value={inputValues.phone}
                onChange={handleChange}
                name='phone'
                mask="+7(000) 000-00-00"
                placeholder='+7'
                className={style.input} />
              <p className={style.error_text}>{errors.phone}</p>
            </div>
            <div className={style.input_wrapper}>
              <label className={style.label}>Адрес электронной почты</label>
              <IMaskInput
                value={inputValues.email}
                onChange={handleChange}
                name='email'
                className={style.input} />
              <p className={style.error_text}>{errors.email}</p>
            </div>
            <OutsideClickHandler onOutsideClick={() => setSelectIssuedBy(false)}>
              <div className={style.input_wrapper}>
                <label className={style.label}>Кем выдан</label>
                <textarea
                  onChange={(e) => { handleChange(e); issuedBySelectSettings() }}
                  name='issuedBy'
                  style={{ resize: 'none' }}
                  value={inputValues.issuedBy}
                  className={style.input_text} />
                <div className={selectIssuedBy ? style.custom_select_wrapper :
                  style.custom_select_wrapper_disabled}>
                  <ul className={style.select_list}>
                    {issuedByOptions.map((x, index) => (
                      <li onClick={() => { issuedBySelect(x.value); setSelectIssuedBy(false) }} key={index} className={style.list_item}>{x.value}</li>))}
                  </ul>
                </div>
                <p className={style.error_text}>{errors.issuedBy}</p>
              </div>
            </OutsideClickHandler>
            <div className={style.input_wrapper}>
              <label className={style.label}>Дата выдачи</label>
              <IMaskInput
                value={inputValues.dateOfIssue}
                mask="00.00.0000"
                onChange={(e) => handleChange(e)}
                name='dateOfIssue'
                className={style.input} />
              <p className={style.error_text}>{errors.dateOfIssue}</p>
            </div>
            <div className={style.input_wrapper}>
              <label className={style.label}>Код подразделения</label>
              <IMaskInput
                value={inputValues.subdivision}
                mask="000-000"
                onChange={(e) => handleChange(e)}
                name='subdivision'
                className={style.input} />
              <p className={style.error_text}>{errors.subdivision}</p>
            </div>
            <div className={style.input_wrapper}>
              <label className={style.label}>Гражданство</label>
              <IMaskInput
                mask='####'
                definitions={{
                  '#': /[а-яА-Я]/,
                }}
                onChange={(e) => handleChange(e)}
                name='citizenship'
                value={inputValues.citizenship}
                className={style.input} />
              <p className={style.error_text}>{errors.citizenship}</p>
            </div>
          </div>
        </div>
        <div className={style.links_wrapper}>
          <button onClick={() => handleSubmit()} className={style.link}>Отправить</button>
        </div>
        <Link to='/inn' style={{ backgroundColor: "red" }} className={style.link}>Назад</Link>
      </div>
    </>

  )
}
