import React from 'react'
import { Link } from 'react-router-dom'
import style from './confirmation.module.css'
import navstyle from '../../components/navbar/navbar.module.css'
import confirmationImg from '../../images/confirmation.svg'
export default function Confirmation() {
  return (
    <>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item}>
          <span>5</span>
        </div>
        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>6</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Выпуск УНЭП</p>
          </div>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>
      <div className={style.container}>
        <div className={style.title_wrapper}>
          <img className={style.title_img} src={confirmationImg} alt="" />
          <h1 className={style.title}>Проверка условий для выпуска УНЭП</h1>
        </div>
        <div className={style.confirmation_content}>
          <p className={style.confirmation_text}>Для выпуска УНЭП необходимо иметь подтвержденную учетную запись в ЕСИА (на сайте gosuslugi.ru)</p>
          <div className={style.button_wrapper}>
            <Link to="/downloadinstruction">
              <button className={style.button}>Есть подтвержденная учетная запись в ЕСИА</button>
            </Link>
          </div>
          <p className={style.confirmation_text_small}>Ознакомиться с инструкцией по проверке учетной записи в ЕСИА</p>
          <p className={style.confirmation_text}>Если у вас нет подтвержденной учетной записи или вообще нет учетной записи в ЕСИА - посмотрите как ее создать или подтвердить. </p>
          <div className={style.button_wrapper}>
            <Link to="/esiainstruction">
              <button className={style.button}>Нет учетной записи в ЕСИА</button>
            </Link>
          </div>
          <div className={style.button_wrapper}>
            <Link to="/editdata">
              <button className={style.button}>Назад</button>
            </Link>
          </div>

        </div>
      </div>
    </>
  )
}
