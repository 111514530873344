import React, { useEffect, useState } from "react"
import styles from "./users.module.css"
import PropTypes from 'prop-types';
import icon from "./images/icon.svg"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from "react-router-dom";
import axios from "axios";
import components from "./components.module.css";
import urls from "../../urls.json";
import itmonitoring from "./images/itmonitoring.svg"
import signme from "./images/signme.png"
import goskey from "./images/goskey.png"
import tochka from "./images/tochka.png"
import { Backdrop, CircularProgress } from "@mui/material";
export default function Users() {

  async function remove(id) {
    setLoader(true)
    await axios.post(`${urls.url}/api/admin/delete/${id}`, {}, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } }).then(response => {
      GetData()
    })
    setLoader(false)
  }
  async function GetData() {
    setLoader(true)
    await axios.get(`${urls.url}/api/admin/users`, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
      .then(response => {
        setRows(response.data)
      })
      .catch(error => {
        window.location.href = "/admin/login"
      })
    setLoader(false)
  }
  const [rows, setRows] = useState([])
  const [isLoader, setLoader] = useState(true)
  useEffect(() =>{
  if (rows.length == 0) {
    GetData()
  }}, [])
  return (
    <div className={components.container}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Table stickyHeader aria-label="collapsible table" >
        <TableHead>
          <TableRow>
            {/* <TableCell /> */}
            <TableCell align="center"></TableCell>
            <TableCell align="center">Статус</TableCell>
            <TableCell align="center">Кем создан</TableCell>
            <TableCell align="center">Дата регистрации</TableCell>
            <TableCell align="center">ФИО</TableCell>
            <TableCell align="center">Телефон</TableCell>
            <TableCell align="center">СНИЛС</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
              {/* <TableCell>
                <Link to={`/admin/users/${row.id}`} className={styles.button}>
                  Подробнее <img src={icon}></img>
                </Link>
              </TableCell> */}
              <TableCell align="center" >
                {row.center == 0 && <img src={itmonitoring}></img>}
                {row.center == 1 && <img src={signme}></img>}
                {row.center==3&&<img style={{width:100}} src={goskey}></img>}
                {row.isDeal && <img style={{ width: "60%" }} src={tochka}></img>}
              </TableCell>
              <TableCell>
                <TableCell align="center" style={{ display: 'flex', justifyContent: 'center', border: "none" }}>
                  {row.status == 0 && <span className={components.badge} style={{ backgroundColor: "orange" }}>создана</span>}
                  {row.status == 1 && <span className={components.badge} style={{ backgroundColor: "#255f0b" }}>паспорт</span>}
                  {row.status == 2 && <span className={components.badge} style={{ backgroundColor: "#FFC0CB" }}>заполнение</span>}
                  {row.status == 3 && <span className={components.badge} style={{ backgroundColor: "#DA70D6" }}>снилс</span>}
                  {row.status == 4 && <span className={components.badge} style={{ backgroundColor: "#9932CC" }}>инн</span>}
                  {row.status == 5 && <span className={components.badge} style={{ backgroundColor: "#8A2BE2" }}>заполнение</span>}
                  {row.status == 6 && <span className={components.badge} style={{ backgroundColor: "#F08080" }}>заявка УЦ</span>}
                  {row.status == 7 && <span className={components.badge} style={{ backgroundColor: "#FFD700" }}>доставка</span>}
                </TableCell>
              </TableCell>
              <TableCell padding="none" align="center">{row.companyName}</TableCell>

              <TableCell padding="none" align="center" component="th" scope="row">
                {row.regDate}
              </TableCell>
              <TableCell padding="none" align="center">
                <Link to={`/admin/users/${row.id}`}>
                  {row.fio}
                </Link>
              </TableCell>
              <TableCell padding="none" align="center">{row.phone}</TableCell>
              <TableCell padding="none" align="center">{row.snils}</TableCell>
              

              <TableCell padding="none" align="center"><button onClick={() => remove(row.id)} className={components.button} style={{ backgroundColor: "red" }}>Удалить</button></TableCell>
            </TableRow>

          ))}
        </TableBody>
      </Table>
    </div>)
}
