import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import { useState } from 'react'
import { Link } from 'react-router-dom'
import style from './style.module.css'
import yourcenterImg from '../../images/yourcenterSecond.svg'
import qrImg from '../../images/itMonitoringQr.png'
import navstyle from '../../components/navbar/navbar.module.css'
import { IMaskInput } from 'react-imask'
import ModalWindow from '../../components/modal/ModalWindow';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import urls from '../../urls.json'
import axios from 'axios';
import step1 from "./img/step1.jpg"
import step2 from "./img/step2.jpg"
import step3 from "./img/step3.jpg"
import step4 from "./img/step4.jpg"
import step5 from "./img/step5.jpg"
import step6 from "./img/step6.jpg"
import step7 from "./img/step7.jpg"


export default function YourCenterSecond() {
  const id = localStorage.getItem("id")
  const [modalText, setModalText] = useState('Загрузка данных не удалась, обратитетсь в тех поддержку')
  const [modal, setModal] = useState(false)
  const [alias, setAlias] = useState('')
  const [isLoader, setLoader] = useState(false)
  const [signatureId, setSignatureId] = useState(localStorage.getItem("signatureId"))
  const [isForeigner, setForeigner] = useState(localStorage.getItem("isForeigner"))
  const [aliasError, setAliasError] = useState("")
  const handleChange = (value) => {
    setAlias(value.toUpperCase())
  }


 
  async function AliasRequest() {
    if (alias == "") {
      setAliasError("обязательное поле")
    } else if (alias.length < 12) {
      setAliasError("неверный формат")
    }
    if (alias.length === 12) {
      setLoader(true)
      await axios.get(`${urls.url}/api/itmonitoring/twofactor?id=${id}&alias=${alias}`)
        .then(function (response) {
          window.location.href = "/passportloader"
        }).catch(error => {
          setModal(true)
        })
      setLoader(false)
    }
  }
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalWindow isActive={modal} text={modalText} onClose={() => setModal(false)} />

      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div>

        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div>

        </div>

        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>6</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Установка приложения</p>
          </div>
        </div>

        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>
      <div className={style.container}>
        <div className={style.title_wrapper}>
          <img className={style.title_img} src={yourcenterImg} alt="" />
          <h1 className={style.title}>ВАШ УДОСТОВЕРЯЮЩИЙ ЦЕНТР - IT MONITORING</h1>
        </div>
        <div className={style.yourcenter_content_wrapper}>

          <ol className={style.step}>
            <li>Пройдите шаги 1-9 по установке приложения MYDSS</li>
            <li>Создайте профиль</li>
            <img src={step1} alt="" />
            <li>Нажмите кнопку "Зарегистрировать онлайн"</li>
            <img src={step2} alt="" />
            <li>В списке выберите "IT Monitoring Lite"</li>
            <img src={step3} alt="" />
            <li>Прочитайте пользовательское соглашение и нажмите кнопку "Выбрать"</li>
            <img src={step4} alt="" />
            <li>Придумайте название профиля и нажмите кнопку "Далее"</li>
            <img src={step5} alt="" />
            <li>Выберите только что созданный профиль</li>
            <img src={step6} alt="" />
            <li>У вас появится кодовое слово</li>
            <img src={step7} alt="" />
            <li>Введите кодовое слово из приложения в поле ниже ⬇️</li>
          </ol>
          <div className={style.textarea_wrapper}>
            <label className={style.label}>Кодовое слово</label>
            <IMaskInput
              className={style.textarea}
              value={alias}
              onAccept={(value) => handleChange(value)}
              mask='############'
              definitions={{
                '#': /^[A-Za-z\d]+$/,
              }}
            />
            <span style={{color:"red"}}>{aliasError}</span>
          </div>

          <div className={style.qr_text_wrapper}>
            <p className={style.yourcenter_text}>Отсканируйте QR - код или перейдите по<a href='https://mydss.safe-tech.ru/app/' target="_blank" className={style.active_text}>ссылке</a>для установки приложения MYDSS.</p>
            <img src={qrImg} style={{ width: 100 }} alt="" />
          </div>
          <p className={style.support_text}>При возникновении вопросов или сложностей. Обратитесь в <span className={style.active_text}>службу тех поддержки Телеграм.</span> Так же можно обратиться в соответствующий раздел<Link to='/faq' className={style.active_text}>F.A.Q</Link></p>
        </div>

        <div className={style.button_container}>
          <button onClick={() => AliasRequest()} className={style.button}>Далее</button>

        </div>
        <div style={{ marginTop: '0' }} className={style.button_container}>

          {
            signatureId == 2 && isForeigner == 0 &&
            <Link to='/methodchoice' className={style.button_back}>Назад</Link>
          }
          {
            signatureId == 1 && isForeigner == 0 &&
            <Link to='/methodchoice' className={style.button_back}>Назад</Link>

          }
          {
            isForeigner == 1 &&
            <Link to='/editdata' style={{ backgroundColor: "red" }} className={style.button_back}>Назад</Link>
          }
        </div>

      </div>
    </>

  )
}
