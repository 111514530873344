import style from './choosesign.module.css'
import unepImg from '../../images/unep.svg'
import { useNavigate } from 'react-router-dom';
import ukepImg from '../../images/ukep.svg'
import * as React from 'react';
import Box from '@mui/material/Box';
import components from "./components.module.css"
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import urls from '../../urls.json'
import ModalWindow from '../../components/modal/ModalWindow';
import Radio from '@mui/material/Radio';
import signmelogo from "./images/signme.png"
import itmonitoringlogo from "./images/itmonitoring.svg"
import { Backdrop, CircularProgress } from '@mui/material';
import { IMaskInput } from 'react-imask';
import tochkaLogo from "./images/tochka.png"
const axios = require('axios').default;

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  border: 'none!important',
  justifyContent: "center",
  flexDirection: "column",
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 10,
  p: 4,
  gap: 2
};


export default function ChooseSign() {
  const [center, setCenter] = React.useState('itmonitoring');
  const [id, setId] = useState(null)
  const [modalText, setModalText] = useState('')
  const [modal, setModal] = useState(false)
  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [patron, setPatron] = useState("")
  const [phone, setPhone] = useState("")
  const [isLoader, setLoader] = useState(false)
  const [signatureId, setSignatureId] = useState(1)
  const handleClose = () => { setOpen(false); setLink(null) };
  const [link, setLink] = useState(null);
  const [status, setStatus] = useState(null)
  function handleOpen(id) {
    setSignatureId(id)
    setOpen(true)
  }
  useEffect(() => {
    GetData()
  }, [])
  const handleChange = (event) => {
    setCenter(event.target.value);
  };
  async function GetData() {
    setLoader(true)
    await axios.get(`${urls.url}/api/admin/ping`, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
      .catch(error => {
        window.location.href = "/admin/login"
      })
    setLoader(false)
  }
  async function sendCode() {
    setLoader(true)
    await axios.post(`${urls.url}/api/admin/send`,
      {
        name: name,
        surname: surname,
        patronymic: patron,
        phone: phone
      }, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
      .then(response => setLink(response.data))
    setLoader(false)
  }
  async function createUser() {
    setLoader(true)
    await axios.post(`${urls.url}/api/admin/user`, {
      name: name, surname: surname, phone: phone, patronymic: patron, verificationCenter: parseInt(center)
    }, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
      .then(response => {
        setLoader(false)
        setStatus("Пользователь создан")
      }).catch(error => {
        setStatus(error.response.data)
        setLoader(false)
      })
  }

  return (
    <div className={style.container}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalWindow isActive={modal} text={modalText} onClose={() => setModal(false)} />
      <h1 className={style.title}>Какой тип электронной подписи необходим вашему клиенту?</h1>
      <div className={style.signs_wrapper} >
        <div className={style.sign_frame} style={{ cursor: "not-allowed" }}>
          <div className={style.question_wrapper}>
            <div className={style.question_mark}>?</div>
          </div>
          <div className={style.img_wrapper}>
            <img className={style.img} src={unepImg} alt="" />
          </div>
          <div>
            <p className={style.sign_name_text}>Усиленная неквалифицированная электронная подпись (УНЭП)</p>
          </div>
        </div>

        <div className={style.sign_frame} onClick={() => handleOpen(2)}>
          <div className={style.question_wrapper}>
            <div className={style.question_mark}>?</div>
          </div>
          <div className={style.img_wrapper}>
            <img className={style.img} src={ukepImg} alt="" />
          </div>
          <div>
            <p className={style.sign_name_text}>Усиленная квалифицированная электронная подпись (УКЭП)</p>
          </div>
        </div>
        <div className={style.sign_frame} style={{ cursor: "not-allowed" }} >
          <div className={style.question_wrapper}>
            <div className={style.question_mark}>?</div>
          </div>
          <div className={style.img_wrapper}>
            <img className={style.img} src={ukepImg} alt="" />
          </div>
          <div>
            <p className={style.sign_name_text}>Усиленная квалифицированная электронная подпись (УКЭП) для сделок на вторичном рынке (с дополнительной банковской идентификацией сторон сделки).</p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <span>Введите данные клиента-физического лица</span>
          <TextField id="outlined-basic" value={surname} onChange={(e) => { setSurname(e.target.value); setId(null) }} label="Фамилия" variant="outlined" />
          <TextField id="outlined-basic" value={name} onChange={(e) => { setName(e.target.value); setId(null) }} label="Имя" variant="outlined" />
          <TextField id="outlined-basic" value={patron} onChange={(e) => { setPatron(e.target.value); setId(null) }} label="Отчество" variant="outlined" />
          <IMaskInput
            className="MuiFormControl-root MuiTextField-root css-i44wyl"
            id="imask"
            radix="."
            value={phone}
            mask={"+7(000) 000-00-00"}
            onAccept={

              (value, mask) => { setPhone(value); setId(null) }
            }
            placeholder="+7(000) 000-00-00"
          />

          {signatureId === 2 &&
            <React.Fragment>
              <span>Где выпустить УКЭП?</span>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: 'center' }}>
                  <Radio
                    checked={center === "0"}
                    onChange={handleChange}
                    value="0"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                  <img style={{ width: "30%", height: "30%" }} src={itmonitoringlogo}></img>
                </div>
                <div style={{ display: "flex", alignItems: 'center' }}>
                  <Radio
                    checked={center === "1"}
                    onChange={handleChange}
                    value="1"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'B' }}
                  />
                  <img style={{ width: "30%", height: "30%" }} src={signmelogo}></img>
                </div>

              </div>

            </React.Fragment>
          }
          <button style={{ padding: 10, borderRadius: 20, border: "none", cursor: "pointer" }} onClick={() => createUser()}>Создать пользователя</button>
          <p>{status}</p>


          <button style={{ padding: 10, borderRadius: 20, border: "none", cursor: "pointer" }} onClick={() => sendCode()}>Отправить ссылку</button>
          {link != null && <p>ссылка отправлена</p>}
          <p>{link}</p>

        </Box>
      </Modal>

    </div>
  )
}
