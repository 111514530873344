import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import { Link } from 'react-router-dom'
import style from './manualdata.module.css'
import { useEffect } from 'react';
import { IMaskInput } from 'react-imask';
import navstyle from '../../components/navbar/navbar.module.css'
import urls from '../../urls.json'
import axios from 'axios';
import { Backdrop, CircularProgress } from '@mui/material';

export default function ManualDataFill() {
  const [adressOptions, setAdressOptions] = useState([])
  const [issuedByOptions, setIssuedByOptions] = useState([])
  const [isLoader, setLoader] = useState(false)
  const [selectGender, setSelectGender] = useState(false)
  const [selectBirthPlace, setSelectBirhtPlace] = useState(false)
  const [selectRegAdress, setSelectRegAdress] = useState(false)
  const [selectIssuedBy, setSelectIssuedBy] = useState(false)
  const selectItems = [
    'Сергеевич',
    'Пушкин',
    'Александр',
  ]

  const [inputValues, setInputValue] = useState({})


  useEffect(() => {
    const source = axios.CancelToken.source();
    (async ()=>{
   await axios.get(`${urls.url}/api/dadata/address?query=${inputValues.regAddress}`,{ cancelToken: source.token})
      .then(function (response) {
        console.log(response.data)
        setAdressOptions(response.data)
        if (response.data.length == 0) {
          setAdressOptions([{ value: 'Ничего не найдено' }])
        }
      }).catch(function (error) {
        setAdressOptions([{ value: 'Ничего не найдено' }])

      })})();
      return () => {
        source.cancel("Pervious request canceled");
      };
      
  }, [inputValues.regAddress])

  useEffect(() => {
    const source = axios.CancelToken.source();
    (async ()=>{
     await axios.get(`${urls.url}/api/dadata/issuedby?query=${inputValues.issuedBy}`, { cancelToken: source.token })
      .then(function (response) {
        console.log(response)
        setIssuedByOptions(response.data)
        if (response.data.length == 0) {
          setIssuedByOptions([{ value: 'Ничего не найдено' }])
        }
      }).catch(function (error) {
        setIssuedByOptions([{ value: 'Ничего не найдено' }])
      })
    })();
    return () => {
      source.cancel("Pervious request canceled");
    };
    
  }, [inputValues.issuedBy])

  const [errors, setErrors] = useState({})
  function handleChange(event) {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
  }


  function regAdressSelect(regAddress) {
    setInputValue({ ...inputValues, regAddress: regAddress })
    setSelectRegAdress(false)
  }
  function issuedBySelect(issuedBy) {
    setInputValue({ ...inputValues, issuedBy: issuedBy })
    setSelectIssuedBy(false)
  }
  // ***
  function issuedBySelectSettings() {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.issuedBy.length == 1) {
      console.log('its working')
      setSelectIssuedBy(false)
    }
    console.count('useEffect count:')
    if (inputValues.issuedBy.length > 1) {
      setSelectIssuedBy(true)

    } else if (inputValues.issuedBy == i) {
      setSelectIssuedBy(false)
    }
  }
  function regAdressSelectSettings() {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.regAddress.length == 1) {
      console.log('its working')
      setSelectRegAdress(false)
    }
    console.count('useEffect count:')
    if (inputValues.regAddress.length > 1) {
      setSelectRegAdress(true)

    } else if (inputValues.regAddress == i) {
      setSelectRegAdress(false)
    }
  }

  const checkValidation = () => {
    const errors = {};
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^.{16,}$/;
    const [day, month, year] = inputValues.birthday.split('.')
    const [dayOfIssue, monthOfIssue, yearOfissue] = inputValues.dateOfIssue.split('.')
    if (!inputValues.surname) {
      errors.surname = 'обязательное поле'
    } else if (inputValues.surname.length < 3) {
      errors.surname = 'длина менеe 2 символов'
    }


    if (!inputValues.name) {
      errors.name = 'обязательное поле'
    }
    else if (inputValues.name.length < 3) {
      errors.name = 'длина менеe 2 символов'
    }
    if (!inputValues.fathersname) {
      errors.fathersname = 'обязательное поле'
    } else if (inputValues.fathersname.length < 3) {
      errors.fathersname = 'длина менеe 2 символов'
    }
    if (!inputValues.gender) {
      errors.gender = 'обязательное поле'
    }
    if (!inputValues.birthday) {
      errors.birthday = 'обязательное поле'
    }
    if (!inputValues.issuedBy) {
      errors.issuedBy = 'обязательное поле'
    }
    if (inputValues.dateOfIssue.length < 10) {
      errors.dateOfIssue = 'неверный формат'
    }
    if (!inputValues.placeOfBirth) {
      errors.placeOfBirth = 'обязательное поле'
    }

    if (!inputValues.regAddress) {
      errors.regAddress = 'обязательное поле'
    }

    if (inputValues.series.length < 5) {
      errors.series = 'неверный формат'
    }
    if (inputValues.subdivision.length < 7) {
      errors.subdivision = 'неверный формат'
    }
    if (inputValues.number.length < 6) {
      errors.number = 'неверный формат'
    }
    if (inputValues.birthday.length < 10) {
      errors.birthday = 'неверный формат'
    }
    if (day > 31) {
      errors.birthday = 'неверный формат'
    }
    if (month > 12) {
      errors.birthday = 'неверный формат'
    }
    if (year < 1900) {
      errors.birthday = 'неверный формат'
    }
    if (dayOfIssue > 31) {
      errors.dateOfIssue = 'неверный формат'
    }
    if (monthOfIssue > 12) {
      errors.dateOfIssue = 'неверный формат'
    }
    if (yearOfissue < 1900) {
      errors.dateOfIssue = 'неверный формат'
    }
    return errors
  }
 
  async function getUserData() {
    setLoader(true)
    await axios.get(`${urls.url}/api/user?id=${localStorage.getItem('id')}`).then(response => {
      setInputValue({
        name: response.data.name,
        surname: response.data.surname,
        fathersname: response.data.patronymic,
        series: response.data.serial,
        number: response.data.number,
        dateOfIssue: response.data.issueDate,
        subdivision: response.data.subDivisionCode,
        birthday: response.data.birthDate,
        regAddress: response.data.regAddress,
        issuedBy: response.data.subDivisionAddress,
        placeOfBirth: response.data.birthPlace,
        gender: response.data.gender === 1 ? "МУЖ" : "ЖЕН"
      })
      setLoader(false)
    })

  }
  useEffect(() => {
    getUserData()
  }, [])
  async function next() {
    setLoader(true)
    let errors = checkValidation(inputValues)
    setErrors(errors)
    console.log(errors)

    const body = {
      "id": localStorage.getItem("id"),
      "patronymic": inputValues.fathersname,
      "name": inputValues.name,
      "surname": inputValues.surname,
      "serial": inputValues.series,
      "number": inputValues.number,
      "regAddress": inputValues.regAddress,
      "birthPlace": inputValues.placeOfBirth,
      "birthDate": inputValues.birthday,
      "issueDate": inputValues.dateOfIssue,
      "subDivisionCode": inputValues.subdivision,
      "subDivisionAddress": inputValues.issuedBy,
      "gender": inputValues.gender === "МУЖ" ? 1 : 2,
    }
    await axios.put(`${urls.url}/api/user/update`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      // innRequest()
      window.location.href = "/snils"
    }).catch(error => {
      console.log("request is unsuccsesful")
    });
    setLoader(false)

  };
  // ******

  // *********
  // **********

  return (
    <><Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>2</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Ввод паспортных данных</p>
          </div>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>6</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>
      <div className={style.container}>
        <div >
          <div className={style.wrapper}>
            <div className={style.inputs_column}>
              <div className={style.input_wrapper}>
                <label className={style.label}>Фамилия</label>
                <IMaskInput
                  onChange={(e) => handleChange(e)}
                  name='surname'
                  mask='##############################'
                  value={inputValues.surname}
                  definitions={{ '#': /[а-яА-Я]/, }}
                  className={style.input} />
                <p className={style.error_text}>{errors.surname}</p>
              </div>
              <div className={style.input_wrapper}>
                <label className={style.label}>Имя</label>
                <IMaskInput
                  mask='###############################'

                  definitions={{
                    '#': /[а-яА-Я]/,
                  }}
                  onChange={(e) => { handleChange(e) }}
                  name='name'
                  value={inputValues.name}
                  className={style.input} />


                <p className={style.error_text}>{errors.name}</p>
              </div>

              <div className={style.input_wrapper}>
                <label className={style.label}>Отчество</label>
                <IMaskInput
                  mask='###############################'

                  definitions={{
                    '#': /[а-яА-Я]/,
                  }}

                  onChange={(e) => handleChange(e)}
                  name='fathersname'
                  value={inputValues.fathersname}
                  className={style.input} />

                <p className={style.error_text}>{errors.fathersname}</p>
              </div>
              <div className={style.input_wrapper}>
                <label className={style.label}>Дата рождения</label>
                <IMaskInput

                  mask="00.00.0000"
                  onChange={(e) => handleChange(e)}
                  name='birthday'
                  value={inputValues.birthday}
                  className={style.input} />
                <p className={style.error_text}>{errors.birthday}</p>
              </div>


              <OutsideClickHandler onOutsideClick={() => setSelectGender(false)}></OutsideClickHandler>
              <div className={style.input_wrapper}>
                <label className={style.label}>Пол</label>
                <div onClick={() => setSelectGender(true)} className={style.input_gender}>
                  <span style={{ margin: '0 0 10px 0' }}>{inputValues.gender}</span>
                </div>

                <div className={selectGender ? style.custom_select_wrapper :
                  style.custom_select_wrapper_disabled}>
                  <p onClick={() => { setInputValue({ ...inputValues, gender: 'МУЖ' }); setSelectGender(false) }} className={style.list_item} value="Мужской">Мужской</p>
                  <p onClick={() => { setInputValue({ ...inputValues, gender: 'ЖЕН' }); setSelectGender(false) }} className={style.list_item} value="Женский">Женский</p>
                </div>
                <p className={style.error_text}>{errors.gender}</p>
              </div>

              <OutsideClickHandler onOutsideClick={() => setSelectBirhtPlace(false)}>
                <div className={style.input_wrapper}>
                  <label className={style.label}>Место рождения</label>
                  <textarea

                    onChange={(e) => handleChange(e)}
                    name='placeOfBirth'
                    style={{ resize: 'none' }}
                    value={inputValues.placeOfBirth}
                    className={style.input_large} />

                  <p className={style.error_text}>{errors.placeOfBirth}</p>
                  <div className={selectBirthPlace ? style.custom_select_wrapper :
                    style.custom_select_wrapper_disabled}>

                  </div>
                </div>
              </OutsideClickHandler>


            </div>

            <div>
              <div className={style.input_wrapper_small}>
                <div className={style.input_wrapper}>
                  <label className={style.label}>Серия</label>
                  <IMaskInput
                    mask="00 00"
                    onChange={(e) => handleChange(e)}
                    name='series'
                    value={inputValues.series}
                    className={style.input_small} />
                  <p className={style.error_text}>{errors.series}</p>
                </div>
                <div className={style.input_wrapper}>
                  <label className={style.label}>Номер</label>
                  <IMaskInput
                    mask="000000"
                    onChange={(e) => handleChange(e)}
                    name='number'
                    value={inputValues.number}
                    className={style.input_small} />
                  <p className={style.error_text}>{errors.number}</p>
                </div>

                <div className={style.input_wrapper}>
                  <label className={style.label}>Дата выдачи</label>
                  <IMaskInput

                    mask="00.00.0000"
                    onChange={(e) => handleChange(e)}
                    name='dateOfIssue'
                    value={inputValues.dateOfIssue}
                    className={style.input} />
                  <p className={style.error_text}>{errors.dateOfIssue}</p>
                </div>
              </div>
              <div className={style.input_wrapper_code}>
                <label className={style.label}>Код подразделения</label>
                <IMaskInput
                  mask="000-000"
                  onChange={(e) => handleChange(e)}
                  name='subdivision'
                  value={inputValues.subdivision}
                  className={style.input} />
                <p className={style.error_text}>{errors.subdivision}</p>
              </div>
              <OutsideClickHandler onOutsideClick={() => setSelectIssuedBy(false)}>
                <div className={style.input_wrapper}>
                  <label className={style.label}>Кем выдан</label>
                  <textarea
                    onChange={(e) => { handleChange(e); issuedBySelectSettings() }}
                    name='issuedBy'
                    style={{ resize: 'none' }}
                    value={inputValues.issuedBy}
                    className={style.input_large} />
                  <div className={selectIssuedBy ? style.custom_select_wrapper :
                    style.custom_select_wrapper_disabled}>
                    <ul className={style.select_list}>
                      {issuedByOptions.map((x) => (
                        <li onClick={() => { issuedBySelect(x.value); setSelectIssuedBy(false) }} className={style.list_item}>{x.value}</li>))}
                    </ul>
                  </div>
                  <p className={style.error_text}>{errors.issuedBy}</p>
                </div>
              </OutsideClickHandler>



              <OutsideClickHandler onOutsideClick={() => setSelectRegAdress(false)}>
                <div className={style.input_wrapper}>
                  <label className={style.label}>Адрес регистрации</label>
                  <textarea

                    onChange={(e) => { handleChange(e); regAdressSelectSettings() }}
                    name='regAddress'
                    style={{ resize: 'none' }}
                    value={inputValues.regAddress}
                    className={style.input_large} />
                  <div className={selectRegAdress ? style.custom_select_wrapper :
                    style.custom_select_wrapper_disabled}>
                    <ul className={style.select_list}>
                      {adressOptions.map((x) => (
                        <li onClick={() => { regAdressSelect(x.value); setSelectRegAdress(false) }} className={style.list_item}>{x.value}</li>))}
                    </ul>
                  </div>
                  <p className={style.error_text}>{errors.regAddress}</p>
                </div>

              </OutsideClickHandler>


            </div>

          </div>
          <div className={style.button_wrapper}>
            <button onClick={() => next()} className={style.button}>Далее</button>
            <div>
              <Link to='/passportupload' style={{ backgroundColor: "red" }} className={style.button}>Назад</Link>
            </div>
          </div>

        </div>

      </div>
    </>

  )
}
