import React, { useEffect, useState } from 'react'
import firstPageImg from '../../images/firstpage.svg'
import style from './style.module.css'
import Switcher from '../../components/switch/Switch';
import { Link, useParams } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import navstyle from '../../components/navbar/navbar.module.css'
import axios from 'axios';
import urls from '../../urls.json'
import Navbar from '../../components/navbar/Navbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function StartForm() {
  const id = localStorage.getItem("id")

  // Switchers
  const [russian, setRussian] = useState(true)
  const [documents, setDocuments] = useState(false)
  const [personalData, setPersonalData] = useState(false)
  const [gosUslugi, setGosUslugi] = useState(false)
  const [passport, setPassport] = useState(false)
  const [gosKey, setGosKey] = useState(false)
  const [nfc, setNfc] = useState(false)
  // Switchers


  const [isLoader, setLoader] = useState(false)
  const [surname, setSurname] = useState("")
  const [name, setName] = useState("")
  const [fathersname, setFathersname] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")

  async function GetData() {
    setLoader(true)
    await axios.get(`${urls.url}/api/user?id=${id}`)
      .then(response => {
        localStorage.setItem("center", response.data.center)
        setPhone(response.data.phone)
        setName(response.data.name)
        setSurname(response.data.surname)
        setFathersname(response.data.patronymic)
        setEmail(response.data.email)
      })
      .catch(error => {
        if (error.response.status === 404) {
          window.location.href = "/404"
        }
      })
    setLoader(false)
  }
  useEffect(() => {
    GetData()
   
  }, [])

  const [errors, setErrors] = useState({})

  const checkValidation = () => {
    const errors = {};
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // const phoneRegex = /^.{20,}$/;
    if (!surname) {
      errors.surname = 'обязательное поле'
    } else if (surname.length < 2) {
      errors.surname = "длина менеe 2 символов"
    }
    if (!phone) {
      errors.phone = 'обязательное поле'
    } else if (phone.length < 17) {
      errors.phone = 'неверный формат'
    }
    if (!email) {
      errors.mail = 'обязательное поле'
    }
    if (!regex.test(email)) {
      errors.mail = 'неверный формат'
    }
    if (!name) {
      errors.name = 'обязательное поле'
    } else if (name.length < 2) {
      errors.name = "длина менеe 2 символов"
    }
    
    return errors
  }
 async function  handleSubmit (e) {
    let link = e.target.parentNode.href
    let errors = checkValidation()
    setErrors(errors)
   
    const body = {
      "id": localStorage.getItem("id"),
      "patronymic": fathersname,
      "name": name,
      "surname": surname,
      "email": email,
      "phone": phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('-', ''),
      "isGoskey":nfc
    }
   await axios.put(`${urls.url}/api/user/first`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response=> {
        if (response.status === 200) {
          window.location.href = link
        } else {
          console.log("request is unsuccsesful")

        }
      }).catch(error=> {
        console.log("request is unsuccsesful")
      });
  };
  // ******

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={navstyle.navbar}>
        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>1</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Ввод персональных данных</p>
          </div>
        </div>

        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item}>
          <span>2</span>
        </div>


        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>6</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>
      <div className={style.container}>

          <div className={style.title_wrapper}>
            <img src={firstPageImg} alt="" />
            <h1 className={style.title}>Данные для оформления электронной подписи</h1>
          </div>
          <div className={style.wrapper}>
            <div className={style.input_plate}>
              <div className={style.input_wrapper}>
                <label className={style.label}>Фамилия *</label>
                <IMaskInput
                  autoComplete='off'
                  onAccept={(value) => setSurname(value)}
                  mask='##############################'
                  value={surname}
                  definitions={{ '#': /[а-яА-Я]/, }}
                  className={style.input} />
                <p className={style.error_text}>{errors.surname}</p>
              </div>
              <div className={style.input_wrapper}>
                <label className={style.label}>Имя *</label>
                <IMaskInput
                  mask='###############################'
                  definitions={{
                    '#': /[а-яА-Я]/,
                  }}
                  autoComplete='off'
                  onAccept={(value) => setName(value)}
                  value={name}
                  className={style.input} />
                <p className={style.error_text}>{errors.name}</p>
              </div>
              <div className={style.input_wrapper}>
                <label className={style.label}>Отчество</label>
                <IMaskInput
                  mask='###############################'
                  definitions={{
                    '#': /[а-яА-Я]/,
                  }}
                  onAccept={(value) => setFathersname(value)}
                  value={fathersname}
                  className={style.input} />
                <p className={style.error_text}>{errors.fathersname}</p>
              </div>
            </div>
            <div className={style.input_plate}>
              <div className={style.input_wrapper}>
                <label className={style.label}>Номер телефона *</label>
                <IMaskInput
                  className={style.input}
                  placeholder='+7(000) 000-00-00'
                  onAccept={(value) => setPhone(value)}
                  value={phone}
                  mask="+7(000) 000-00-00"
                />
                <p className={style.error_text}>{errors.phone}</p>
              </div>
              <div className={style.input_wrapper}>
                <label className={style.label}>Электронная почта *</label>
                <IMaskInput
                  value={email}
                  mask={"***********************"}
                  onAccept={(value) => setEmail(value.replace(" ", ""))}
                  className={style.input} />
                <p className={style.error_text}>{errors.mail}</p>
              </div>
            </div>
          </div>
          <div>
            <div className={style.switch_container}>
              <div className={style.switcher_frame}>
                <Switcher checked={russian} onClick={() => setRussian(!russian)} />
              </div>
              <span className={style.switch_label}>Я гражданин РФ</span>
            </div>
            <div className={style.switch_container}>
              <div className={style.switcher_frame}>
                <Switcher checked={documents} onClick={() => setDocuments(!documents)} />
              </div>
              <span className={style.switch_label}>Знаю СНИЛС и ИНН</span>
            </div>
            <div className={style.switch_container}  style={{opacity:0.5,pointerEvents:"none"}}>
              <div className={style.switcher_frame}>
                <Switcher checked={gosKey}  onClick={() => setGosKey(!gosKey)} />
              </div>
              <span className={style.switch_label}>Выпустить УКЭП в Госключе</span>
            </div>
            {gosKey &&
              <div className={style.switch_container}>
                <div className={style.switcher_frame}>
                  <Switcher checked={gosUslugi} onClick={() => setGosUslugi(!gosUslugi)} />
                </div>
                <span className={style.switch_label}>Есть подтверждённая учётная запись в Госуслугах</span>
              </div>
            }
            {(gosKey && gosUslugi) &&
              <div className={style.switch_container}>
                <div className={style.switcher_frame}>
                  <Switcher checked={passport} onClick={() => setPassport(!passport)} />
                </div>
                <span className={style.switch_label}>Есть загран. паспорт на 10 лет</span>
              </div>
            }
            {(gosKey && gosUslugi && passport) &&
              <div className={style.switch_container}>
                <div className={style.switcher_frame}>
                  <Switcher checked={nfc} onClick={() => setNfc(!nfc)} />
                </div>
                <span className={style.switch_label}>Есть телефон с NFC</span>
              </div>
            }
            <div className={style.switch_container}>
              <div className={style.switcher_frame}>
                <Switcher checked={personalData} onClick={() => setPersonalData(!personalData)} />
              </div>
              <span className={style.switch_label}>Подтверждаю свое согласие на <a target="_blank" href='/personaldata' style={{ color: '#E81616' }}>обработку персональных данных</a></span>
            </div>
          </div>
          <div className={style.button_wrapper} >
            <button disabled={!personalData} style={{ opacity: !personalData ? "0.5" : "1", border: 'none', backgroundColor: '#fff', pointerEvents: !personalData ? 'none' : "all" }}>
              {(russian && documents && !nfc) &&
                <Link className={style.Link} onClick={(e) => handleSubmit(e)} to="/passportupload">
                  <button className={style.button}>Далее</button>
                </Link>
              }
              {(russian && documents && nfc) &&
                <Link className={style.Link} onClick={(e) => handleSubmit(e)} to="/passportupload"  >
                  <button className={style.button}>Далее</button>
                </Link>
              }
              {!russian && documents &&
                <Link className={style.Link} onClick={(e) => handleSubmit(e)} to="/filldatamanualyforeigner"   >
                  <button className={style.button}>Далее</button>
                </Link>
              }
              {(russian && !documents) &&
                <Link className={style.Link} onClick={(e) => handleSubmit(e)} to="/faq"  >
                  <button className={style.button}>Далее</button>
                </Link>
              }
            </button>
          </div>
      </div>
    </>


  )
}
