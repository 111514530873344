import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom"
import urls from "../../urls.json"
import styles from "./userdata.module.css"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import itmonitoring from "./images/itmonitoring.svg"
import signme from "./images/signme.png"
import components from "./components.module.css"
import passport from "./images/passport.png"
import snils from "./images/snils.png"
import request from "./images/request.png"
import goskey from "./images/goskey.png"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import eye from "./images/eye.svg"
import download from "./images/download.svg"
import tochka from "./images/tochka.png"
export default function UserData() {
  const [isLoader, setLoader] = useState(true)
  const { id } = useParams();
  const [document, setDocument] = useState(null)
  const [modal, setModal] = useState(false)
  const [userData, setUserData] = useState([])
  const [choose, setChoose] = useState(0);
  useEffect(() => GetData(), [])

  const GetPhoto = async (id) => {
    setLoader(true)
    await axios.get(`${urls.url}/api/admin/document?id=${id}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
      .then(response => {
        if (response.data.type === "application/pdf") {
          const data = `data:${response.data.type};base64,${response.data.base64}`
          const pdf = window.open("")
          pdf.document.write(`<iframe width='100%' height='100%' src='${data}'></iframe>`)
        }
        else {
          setDocument(response.data)
          setModal(true)
        }
      })
      .catch(error => {
        window.location.href = "/admin/login"
      })
    setLoader(false)

  }
  async function GetData() {
    await axios.get(`${urls.url}/api/admin/user?id=${id}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        setUserData(response.data)
      })
      .catch(error => {
        window.location.href = "/admin/login"
      })
    setLoader(false)
  }
  return (<div className={components.container}>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.modal}>
        {/* {document?.type==="application/pdf"&&
            <iframe  className={styles.iframe} src={`data:${document.type};base64, ${document.base64}`}></iframe>} */}
        {document?.type === "image/jpeg" &&
          <>
            <img style={{ width: 500, height: 600, borderRadius: 15, border: "none", outline: "none" }} src={`data:${document.type};base64, ${document.base64}`}></img>
            <button style={{ marginTop: 10 }} onClick={() => setModal(false)}>Закрыть</button>
          </>
        }
      </div>
    </Modal>
    <div className={styles.nav}>
      <button onClick={() => setChoose(0)} className={components.button}>Заявки</button>
      <button onClick={() => setChoose(1)} className={components.button}>Документы</button>
      <button onClick={() => setChoose(2)} className={components.button}>Доставка</button>
      <button onClick={() => setChoose(3)} className={components.button}>История</button>
    </div>
    <div style={{marginLeft:20}}>
    <h3>{userData.fio}</h3>
    <h5>Email: {userData.email}</h5>
    <h5>Номер телефона: {userData.phone}</h5>
    <h5>СНИЛС: {userData.snils}</h5>
    <h5>ИНН: {userData.inn}</h5>
    <h5>Дата рождения: {userData.birthDate}</h5>
    <h5>Место рождения: {userData.birthPlace}</h5>
    <h5>Дата выдачи: {userData.issueDate}</h5>
    <h5>Кем выдан: {userData.subdivisionAddress}</h5>
    <h5>Код подразделения: {userData.subdivisionCode}</h5>
    <h5>Адрес регистрации: {userData.regAddress}</h5>

    </div>
    
    <Table stickyHeader aria-label="collapsible table">
      {choose == 0 &&
        renderRequests(userData)
      }
      {choose == 1 &&
        renderDocuments(userData, GetPhoto)
      }
      {choose == 2 &&
        renderDeliveries(userData)
      }
      {choose == 3 &&
        renderHistory(userData)
      }
    </Table>
  </div>)
}
function renderHistory(userData) {
  return (
    <React.Fragment>
      <TableHead>
        <TableRow>
          <TableCell align="center">Дата</TableCell>
          <TableCell align="center">Событие</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {userData.histories?.map((row) => (
          <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >

            <TableCell align="center">{row.created}</TableCell>
            <TableCell align="center">{row.event}</TableCell>

          </TableRow>
        ))}
      </TableBody>
    </React.Fragment>
  );
}
function renderDocuments(userData, func) {

  return (
    <React.Fragment>
      <TableHead>
        <TableRow>
          <TableCell align="center">Тип документа</TableCell>
          <TableCell align="center">Дата загрузки</TableCell>
          <TableCell ></TableCell>
          <TableCell></TableCell>

        </TableRow>
      </TableHead>
      <TableBody>
        {userData.documents?.map((row) => (
          <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >

            <TableCell align="center">
              {row.type == 6 &&
                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", gap: 10 }}>
                  <img style={{ width: '10%' }} src={passport}></img><span>Паспорт</span>
                </div>}
              {row.type == 7 &&
                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", gap: 10 }}>
                  <img style={{ width: '10%' }} src={snils}></img><span>Снилс</span>
                </div>
              }
              {row.type == 1 &&
                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", gap: 10 }}>
                  <img style={{ width: '10%' }} src={request}></img><span>Заявление</span>
                </div>}
              {row.type == 2 &&
                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", gap: 10 }}>
                  <img style={{ width: '10%' }} src={request}></img><span>Заявление (фото)</span>
                </div>}
              {row.type == 15 &&
                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", gap: 10 }}>
                  <img style={{ width: '10%' }} src={request}></img><span>Уведомление</span>
                </div>}
            </TableCell>
            <TableCell align="center">{row.created}</TableCell>
            <TableCell align="center" ><div onClick={() => func(row.id)} className={styles.show_btn}>
              <span>Открыть</span>
              <img src={eye}></img>
            </div></TableCell>
            <TableCell align="center"><div onClick={() => func(row.id)} className={styles.download_btn}>
              <span>Скачать</span>
              <img src={download}></img>
            </div></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </React.Fragment>
  );
}
function renderDeliveries(userData) {
  return (
    <React.Fragment>
      <TableHead>
        <TableRow>
          <TableCell align="center">Дата создания</TableCell>
          <TableCell align="center">Дата доставки</TableCell>
          <TableCell align="center">Время</TableCell>
          <TableCell align="center">Кладр</TableCell>
          <TableCell align="center">УЦ</TableCell>
          <TableCell align="center">Адрес</TableCell>
          <TableCell align="center">Статус</TableCell>
          <TableCell align="center">Трек номер</TableCell>

        </TableRow>
      </TableHead>
      <TableBody>
        {userData.deliveries?.map((row) => (
          <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >

            <TableCell align="center">{row.created}</TableCell>
            <TableCell align="center">{row.deliveryDate}</TableCell>
            <TableCell align="center">{row.time}</TableCell>
            <TableCell align="center">{row.cladr}</TableCell>
            <TableCell align="center">
              {row.center == 0 && <img src={itmonitoring}></img>}
              {row.center == 1 && <img src={signme}></img>}
              {row.center == 3 && <img style={{width:"60%"}} src={tochka}></img>}

            </TableCell>
            <TableCell align="center">{row.address}</TableCell>
            <TableCell align="center">
              {row.status == 0 && <span className={components.badge} style={{ backgroundColor: "orange" }}>доставка</span>}
              {row.status == 1 && <span className={components.badge} style={{ backgroundColor: "#255f0b" }}>доставлено</span>}
            </TableCell>
            <TableCell align="center">{row.trackNumber}</TableCell>

          </TableRow>
        ))}
      </TableBody>
    </React.Fragment>
  );
}
function renderRequests(userData) {
  return (
    <React.Fragment>
      <TableHead>
        <TableRow>
          <TableCell align="center">ID заявки</TableCell>
          <TableCell align="center">УЦ</TableCell>
          <TableCell align="center">Дата создания</TableCell>
          <TableCell align="center">Статус</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {userData.requests?.map((row) => (
          <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >

            <TableCell align="center">{row.requestId}</TableCell>
            <TableCell align="center">
              {row.center == 0 && <img src={itmonitoring}></img>}
              {row.center == 1 && <img src={signme}></img>}
              {row.center == 3 && <img style={{width:100}} src={goskey}></img>}

            </TableCell>
            <TableCell align="center">{row.created}</TableCell>
            <TableCell align="center">
              {row.status == 0 && <span className={components.badge} style={{ backgroundColor: "red" }}>создание</span>}
              {row.status == 1 && <span className={components.badge} style={{ backgroundColor: "orange" }}>заполнение</span>}
              {((row.status == 3 || row.status == 2) && !row.isAuthenticated) && <span className={components.badge} style={{ backgroundColor: "orange" }}>идентификация (паспорт)</span>}
              {row.status == 4 && <span className={components.badge} style={{ backgroundColor: "yellow" }}>ожидание (запрос)</span>}
              {row.status == 6 && <span className={components.badge} style={{ backgroundColor: "yellow" }}>ожидание (бланк)</span>}
              {(row.status == 3 && row.isAuthenticated) && <span className={components.badge} style={{ backgroundColor: "orange" }}>ожидание (бланк)</span>}
              {row.status == 10 && <span className={components.badge} style={{ backgroundColor: "green" }}>выпущен</span>}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </React.Fragment>
  );
}