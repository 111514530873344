import React from 'react'
import { useEffect } from 'react'
import style from '../../pages/PreloadPage/preloader.module.css'
import Moonloader from 'react-spinners/MoonLoader'
import navstyle from '../../components/navbar/navbar.module.css'
import { useParams } from 'react-router-dom'
export default function CenterLoader() {
  useEffect(() => {
    setTimeout(Move, 5000);
  }, [])
  function Move() {
    window.location.href = "/delivery"
  }
  return (
    <>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item}>
          <span>6</span>
        </div>

        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>7</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Определение удостоверяющего центра </p>
          </div>
        </div>

      </div>
      <div className={style.container}>
        <div className={style.loader_container}>
          <Moonloader className={style.loader} color="#69CD7C" size={160} speedMultiplier={0.5}></Moonloader>
          <span className={style.loader_text}>Минуточку...</span>
        </div>
        <div className={style.text_wrapper}>
          <h2 className={style.title}>ОЖИДАЕМ ОТВЕТА УДОСТОВЕРЯЮЩЕГО ЦЕНТРА</h2>
          <p className={style.subtitle}>Эта процедура занимает не более <span style={{ color: '#69CD7C' }}> минуты</span></p>

        </div>
      </div>
    </>

  )
}
