import React from 'react'
import { Link, useParams } from 'react-router-dom'
import style from './tracking.module.css'
import toolsImg from '../../images/tools.svg'
import { useEffect } from 'react'
import axios from 'axios'
import urls from "../../urls.json"
import { useState } from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
export default function TrackingNum() {
  const { deliveryId } = useParams()
  const [delivery, setDelivery] = useState(null)
  const [isLoader, setLoader] = useState(true)
  async function getDelivery() {
    await axios.get(`${urls.url}/api/user/delivery/${deliveryId}`).then(response => {
      setDelivery(response.data)
      setLoader(false)
    }).cath(error => {
      setLoader(false)
      alert(error.response.data);
    })
  }
  useEffect(() => {
    getDelivery()
  }, [])
  return (
    <div className={style.container}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={style.title_wrapper}>
        <img className={style.title_img} src={toolsImg} alt="" />
        <h1 className={style.title}>Подготовка электронной подписи</h1>
      </div>
      <p className={style.text_main}>Курьер привезет конверт с документами на подпись <span className={style.data_text}>{delivery?.date}</span> во временном интервале <span className={style.data_text}>{delivery?.time}</span>  по адресу: <span className={style.data_text}>{delivery?.address}</span> </p>
      <p className={style.text_submain}>Информация с трек-номером отправлена вам на почту и телефон в виде <span className={style.text_green}>СМС</span> </p>
      <div className={style.track_num_wrapper}>
        <h2 className={style.tracknum_title}>
          ВАШ ТРЕК-НОМЕР ДЛЯ ОТСЛЕЖИВАНИЯ
        </h2>
        <div className={style.track_num}>
          <p className={style.track_num_text}>{deliveryId}</p>
        </div>
        <p className={style.text_submain}>Не забудьте подготовить <span className={style.text_green}>документы</span> перед встречей с курьером  </p>
      </div>
      <h2 style={{ textAlign: "center" }}>Текущий статус:
        {delivery?.status === 0 && <span style={{ color: "yellow" }}> Доставка</span>}
        {delivery?.status === 1 && <span style={{ color: "#69CD7C" }}> Доставлено</span>}
      </h2>

    </div>
  )
}
