
import styles from './styles.module.css'
import React, { useEffect, useState } from 'react'
import urls from '../../urls.json'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import logo from "./images/signme.png"
export default function Recovery() {
    const [snils, setSnils] = useState("")
    const [errors, setErrors] = useState()
    const [modalText, setModalText] = useState('ЗАГРУЗКА ДАННЫХ НЕ УДАЛАСЬ ПО ПРИЧИНЕ ТАКОЙ-ТО')
    const [modal, setModal] = useState(false)
    const [pdf, setPdf] = useState(localStorage.getItem("pdf"))
    const [isLoader, setLoader] = useState(true)
    function openPdf() {

        let pdfWindow = window.open("")
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdf) + "'></iframe>")
    }
    async function GetSnils() {
        await axios.get(`${urls.url}/api/idx/snils?id=${localStorage.getItem("id")}`).then(response => {
            setSnils(response.data.number.replace(" ", "-"))
        }).catch(error => {
        })
        setLoader(false)
    }
    async function GetRequest() {
        await axios.get(`${urls.url}/api/signme/precheck?id=${localStorage.getItem("id")}`)
            .then(response => {
                const result = response.data;
                if (result.approved) {
                    localStorage.setItem("pdf", result.pdf)
                    setPdf(result.pdf)
                }
            })
        setLoader(false)
    }
    useEffect(() => {

        if (pdf == null) {
            GetRequest()
        }
        GetSnils()
    }, [])
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={styles.container}>
                <img className={styles.logo} src={logo}></img>
                <h2 className={styles.title}>Мы обнаружили, что ранее вы выпускали электронную подпись в Sign.me</h2>
                <h3 className={styles.subtitle}>Если на вашем смартфоне не сохранилось мобильное приложение Sign.me, следуйте инструкции:</h3>
                <ol className={styles.list}>
                    <li>Скачайте приложение Sign.me по <a href='https://sandbox.sign.me/app' target='_blank' className={styles.greenText}>ссылке</a></li>
                    <li >После установки приложения, направьте на адрес <a className={styles.greenText} href={`mailto:support@sign.me?subject=Просьба прислать инструкцию&body=Мобильное приложение установлено, мой СНИЛС: ${snils}, прошу выслать инструкцию по дальнейшим действиям`}>support@sign.me</a> письмо с текстом:
                        <a className={styles.greenText} href={`mailto:support@sign.me?subject=Просьба прислать инструкцию&body=Мобильное приложение установлено, мой СНИЛС: ${snils}, прошу выслать инструкцию по дальнейшим действиям`}>
                            <br /><b className={styles.greenText}>Мобильное приложение установлено, мой СНИЛС: {snils}, прошу выслать инструкцию по дальнейшим действиям</b> </a> с темой письма: <b className={styles.greenText}>Просьба прислать инструкцию</b></li>
                </ol>
                {pdf == null && <span className={styles.greenText}>Загрузка заявления..</span>}
                {pdf != null &&
                    <div onClick={() => openPdf()} className={styles.fileBlock}>
                        <img className={styles.pdfIcon} src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/PDF_icon.svg/1792px-PDF_icon.svg.png'></img>
                        <a className={styles.greenText}>Заявление.pdf</a>
                        <img className={styles.downloadIcon} src="https://freeiconshop.com/wp-content/uploads/edd/download-flat.png"></img>
                    </div>
                }

            </div>
        </>
    );
}