import React from 'react'
import style from './downloading.module.css'
import appstoreImg from '../../images/appstore.svg'
import googleplay from '../../images/googleplay.svg'
import qr from '../../images/downloadqr.svg'
import logo from '../../images/signme.svg'
import navstyle from '../../components/navbar/navbar.module.css'
import { Link } from 'react-router-dom'
export default function AppDownloading() {
  return (
    <>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item}>
          <span>6</span>
        </div>
        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>7</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Установка приложения</p>
          </div>
        </div>
      </div>
      <div className={style.container}>
        <div className={style.title_wrapper}>
          <img className={style.title_img} src={logo} alt="" />
          <h1 className={style.title}>Установка мобильного приложения SIGN.ME</h1>
        </div>
        <p className={style.text_main}>Для дальнейшего выпуска подписи необходимо установить мобильное приложение Sign.me</p>
        <p className={style.text_main}>Скачать его вы можете по QR-коду или перейдя по представленным ниже ссылкам, в официальных магазинах  приложений AppStore или Gooogle Play</p>
        <div className={style.qr_wrapper}>
          <img src={qr} alt="" />
        </div>
        <p className={style.text}>Скачать в:</p>
        <div className={style.store_wrapper}>
          <a href='https://apps.apple.com/ru/app/sign-me/id1502259352' target="_blank">
            <img className={style.apple_img} src={appstoreImg} alt="" />
          </a>
          <a href='https://play.google.com/store/apps/details?id=me.sign' target="_blank">
            <img className={style.google_img} src={googleplay} alt="" />
          </a>
        </div>
        <div className={style.buttons_container}>
          <Link to="/instruction">
            <button className={style.button}>Далее</button>
          </Link>
          <Link to="/confirmation">
            <button className={style.button}>Назад</button>
          </Link>
        </div>
      </div>
    </>
  )
}
