import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import style from './tracking.module.css'
import tracking from '../../images/track.svg'
import { IMaskInput } from 'react-imask';
import axios from 'axios'
import urls from "../../urls.json"

export default function TrackingInfo() {
  const [trackNum, setTrackNum] = useState()
  const [error, setError] = useState("")
  const checkValidation = () => {

    if (!trackNum) {
      setError('обязательное поле')
      return ""
    }
    if (trackNum.length < 6) {
      setError('неверный формат')
      return ""
    }
    return null;
  }

  async function handleSubmit() {
    const error = checkValidation()
    if (error === null) {
      await axios.get(`${urls.url}/api/user/delivery/${trackNum}`)
        .then(response => {
          window.location.href = `/tracknumber/${trackNum}`
        })
        .catch(error => {
          setError("не найдено")
        })
    }
  };
  return (
    <div className={style.container}>
      <div className={style.title_wrapper_info}>
        <img className={style.title_img_info} src={tracking} alt="" />
        <div>
          <h1 className={style.title_info}>Отслеживание статуса доставки документов</h1>
          <p className={style.text_title}>Трек-номер это уникальный идентификатор, по которому  возможно отследить отправление </p>
        </div>
      </div>
      <div className={style.input_wrapper}>
        <label className={style.label}>Введите трек-номер</label>
        <IMaskInput
          onChange={(e) => setTrackNum(e.target.value)}
          mask={"000000000000"}
          value={trackNum}
          className={style.input} />
        <p className={style.error_text}>{error}</p>
      </div>
      <div className={style.buttons_container}>
        <button className={style.button} onClick={() => handleSubmit()}>Получить данные</button>
      </div>

    </div>
  )
}
