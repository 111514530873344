import React from 'react'
import style from './contract.module.css'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { IMaskInput } from 'react-imask'
import navstyle from '../../components/navbar/navbar.module.css'
import urls from '../../urls.json'
import axios from 'axios';
export default function ContractDetails() {
  const [adressOptions, setAdressOptions] = useState([]) 
  const [issuedByOptions, setIssuedByOptions] = useState([]) 
  const [objectAddOptions, setObjectAddOptions] = useState([]) 
  const [buyerregAdressOptions,setBuyerregAdressOptions] = useState([])
  const [buyerissuedByOptions,setBuyerissuedByOptions] = useState([])
  const [placeOfBirthOptions, setPlaceOfBirthOptions] = useState([])
  const [buyerplaceOfBirthOptions, setBuyerPlaceOfBirthOptions] = useState([])

    // validation
// НОВАЯ ВАЛИДАЦИЯ + Селект
const [selectSurname,setSelectSurname]=useState(false)
const [selectName,setSelectName]=useState(false)
const [selectFathersname,setSelectFathersname]=useState(false)
const [selectGender, setSelectGender] = useState(false)
const [selectGenderBuyer, setSelectGenderBuyer] = useState(false)
const [selectType, setSelectType] = useState(false)

const [selectRegAdressBuyer, setselectRegAdressBuyer] = useState(false)
const [selectIssuedByBuyer,setSelectIssuedByBuyer] = useState(false)
const [objectAdressSelect, setObjectAdressSelect] = useState(false)
const [selectBirthPlace, setSelectBirhtPlace] = useState(false)
const [selectBirthPlaceBuyer, setSelectBirhtPlaceBuyer] = useState(false)

const [selectRegAdress, setSelectRegAdress] = useState(false)
const [selectIssuedBy,setSelectIssuedBy] = useState(false)
const selectItems = [
  'Сергеевич',
  'Пушкин',
  'Александр',
]

const [inputValues,setInputValue] = useState({
  surname:localStorage.getItem("surname"),
  name:localStorage.getItem("name"),
  fathersname:localStorage.getItem("fathersname"),
  birthday:localStorage.getItem("birthday"),
  gender:localStorage.getItem("gender"),
  placeOfBirth:localStorage.getItem("placeOfBirth"),
  issuedBy:localStorage.getItem("issuedBy"),
  dateOfIssue:localStorage.getItem("dateOfIssue"),
  series:localStorage.getItem("series"),
  number:localStorage.getItem("number"),
  subdivision:localStorage.getItem("subdivision"),
  regAdress:localStorage.getItem("regAdress"),
  citizenship:'',
  type:'',
  cadastralNum:'',
  objectAdress:'',
  price:'',
  buyersurname:'',
  buyername:'',
  buyerfathersname:'',
  buyerbirthday:'',
  buyergender:'',
  buyerplaceOfBirth:'',
  buyerissuedBy:'',
  buyerdateOfIssue:'',
  buyerseries:'',
  buyernumber:'',
  buyersubdivision:'',
  buyerregAdress:'',
  buyercitizenship:'',
  purchase:'',
})
useEffect(()=>{
  localStorage.setItem("name",inputValues.name)
  localStorage.setItem("surname",inputValues.surname)
  localStorage.setItem("fathersname",inputValues.fathersname)
  localStorage.setItem("birthday",inputValues.birthday)
  localStorage.setItem("gender",inputValues.gender)
  localStorage.setItem("placeOfBirth",inputValues.placeOfBirth)
  localStorage.setItem("issuedBy",inputValues.issuedBy)
  localStorage.setItem("dateOfIssue",inputValues.dateOfIssue)
  localStorage.setItem("series",inputValues.series)
  localStorage.setItem("number",inputValues.number)
  localStorage.setItem("subdivision",inputValues.subdivision)
  localStorage.setItem("regAdress",inputValues.regAdress)

},[inputValues])
useEffect(()=>{
    axios.get(`${urls.url}/api/dadata/address?request=${inputValues.regAdress}`)
  .then(function(response){
    console.log(response)
    setAdressOptions(response.data)
  }).catch(function (error){
    setAdressOptions([{value:'Ничего не найдено'}])
   
  })
},[inputValues.regAdress])
useEffect(()=>{
  axios.get(`${urls.url}/api/dadata/issuedby?request=${inputValues.issuedBy}`)
  .then(function(response){
    console.log(response)
    setIssuedByOptions(response.data)
  }).catch(function (error){
    setIssuedByOptions([{value:'Ничего не найдено'}])
   
  })
},[inputValues.issuedBy])

useEffect(()=>{
  axios.get(`${urls.url}/api/dadata/address?request=${inputValues.placeOfBirth}`)
.then(function(response){
  console.log(response)
  setPlaceOfBirthOptions(response.data)
}).catch(function (error){
  setPlaceOfBirthOptions([{value:'Ничего не найдено'}])
 
})
},[inputValues.placeOfBirth])
useEffect(()=>{
  axios.get(`${urls.url}/api/dadata/address?request=${inputValues.buyerplaceOfBirth}`)
.then(function(response){
  console.log(response)
  setBuyerPlaceOfBirthOptions(response.data)
}).catch(function (error){
  setBuyerPlaceOfBirthOptions([{value:'Ничего не найдено'}])
 
})
},[inputValues.buyerplaceOfBirth])
useEffect(()=>{
  axios.get(`${urls.url}/api/dadata/issuedby?request=${inputValues.buyerissuedBy}`)
.then(function(response){
  console.log(response)
  setBuyerissuedByOptions(response.data)
}).catch(function (error){
  setBuyerissuedByOptions([{value:'Ничего не найдено'}])
 
})
},[inputValues.buyerissuedBy])

useEffect(()=>{
axios.get(`${urls.url}/api/dadata/address?request=${inputValues.buyerregAdress}`)
.then(function(response){
  console.log(response)
  setBuyerregAdressOptions(response.data)
}).catch(function (error){
  setBuyerregAdressOptions([{value:'Ничего не найдено'}])
 
})
},[inputValues.buyerregAdress])

useEffect(()=>{
    axios.get(`${urls.url}/api/dadata/address?request=${inputValues.objectAdress}`)
    .then(function(response){
      console.log(response)
      setObjectAddOptions(response.data)
    }).catch(function (error){
      setObjectAddOptions([{value:'Ничего не найдено'}])
     
    })
},[inputValues.objectAdress])
const [errors,setErrors] = useState({})
function handleChange(event) {
  const { name, value } = event.target;
  setInputValue({ ...inputValues, [name]: value });
}
// handle select
function objectAddSelect(objectAdress){
  setInputValue({...inputValues, objectAdress:objectAdress})
  setObjectAdressSelect(false)
}
function nameSelect(name){
  setInputValue({...inputValues, name:name})
  setSelectName(false)
}
function fathersnameSelect(fathersname){
  setInputValue({...inputValues, fathersname:fathersname})
  setSelectFathersname(false)
}
function birthPlaceSelect(placeOfBirth){
  setInputValue({...inputValues,placeOfBirth:placeOfBirth})
  setSelectFathersname(false)
}
function buyerbirthPlaceSelect(buyerplaceOfBirth){
  setInputValue({...inputValues,buyerplaceOfBirth:buyerplaceOfBirth})
  setSelectBirhtPlaceBuyer(false)
}
function regAdressSelect(regAdress){
  setInputValue({...inputValues,regAdress:regAdress})
  setSelectRegAdress(false)
}
function issuedBySelect(issuedBy){
  setInputValue({...inputValues,issuedBy:issuedBy})
  setSelectIssuedBy(false)
}
function regAdressSelectBuyer(buyerregAdress){
    setInputValue({...inputValues,buyerregAdress:buyerregAdress})
    setselectRegAdressBuyer(false)
  }
  function issuedBySelectBuyer(buyerissuedBy){
    setInputValue({...inputValues,buyerissuedBy:buyerissuedBy})
    setSelectIssuedByBuyer(false)
  }
// ***
function issuedBySelectSettings(issuedBy){
  const i = selectItems.map((x) => (
    x));
    if (inputValues.issuedBy.length == 1){
      console.log('its working')
      setSelectIssuedBy(false)
    }
      console.count('useEffect count:')
      if(inputValues.issuedBy.length > 1){
        setSelectIssuedBy(true)
        
      }else if (inputValues.issuedBy == i){
        setSelectIssuedBy(false)
      }  
}
function objectAdressSelectSettings(objectAdress){
  const i = selectItems.map((x) => (
    x));
    if (inputValues.objectAdress.length == 1){
      console.log('its working')
      setObjectAdressSelect(false)
    }
      console.count('useEffect count:')
      if(inputValues.objectAdress.length > 1){
        setObjectAdressSelect(true)
        
      }else if (inputValues.objectAdress == i){
        setObjectAdressSelect(false)
      }  
}
function issuedBySelectSettingsBuyer(buyerissuedBy){
    const i = selectItems.map((x) => (
      x));
      if (inputValues.buyerissuedBy.length == 1){
        setSelectIssuedByBuyer(false)
      }
        if(inputValues.buyerissuedBy.length > 1){
          setSelectIssuedByBuyer(true)
          
        }else if (inputValues.buyerissuedBy == i){
            setSelectIssuedByBuyer(false)
        }  
  }
function regAdressSelectSettings(regAdress){
  const i = selectItems.map((x) => (
    x));
    if (inputValues.regAdress.length == 1){
      console.log('its working')
      setSelectRegAdress(false)
    }
      console.count('useEffect count:')
      if(inputValues.regAdress.length > 1){
        setSelectRegAdress(true)
        
      }else if (inputValues.regAdress == i){
        setSelectRegAdress(false)
      }  
}
function regAdressSelectSettingsBuyer(buyerregAdress){
    const i = selectItems.map((x) => (
      x));
      if (inputValues.buyerregAdress.length == 1){
        console.log('its working')
        setselectRegAdressBuyer(false)
      }
        console.count('useEffect count:')
        if(inputValues.buyerregAdress.length > 1){
            setselectRegAdressBuyer(true)
          
        }else if (inputValues.buyerregAdress == i){
            setselectRegAdressBuyer(false)
        }  
  }
function nameSelectSettings(name){
  const i = selectItems.map((x) => (
    x));
    if (inputValues.name.length == 1){
      console.log('its working')
      setSelectName(false)
    }
      console.count('useEffect count:')
      if(inputValues.name.length > 1){
        setSelectName(true)
        
      }else if (inputValues.name == i){
        setSelectName(false)
      }  
}

function birthPlaceSelectSettings(placeOfBirth){
  const i = selectItems.map((x) => (
    x));
    if (inputValues.placeOfBirth.length == 1){
      console.log('its working')
      setSelectBirhtPlace(false)
    }
      console.count('useEffect count:')
      if(inputValues.placeOfBirth.length > 1){
        setSelectBirhtPlace(true)
        
      }else if (inputValues.placeOfBirth == i){
        setSelectBirhtPlace(false)
      }  
}


function buyerbirthPlaceSelectSettings(buyerplaceOfBirth){
  const i = selectItems.map((x) => (
    x));
    if (inputValues.buyerplaceOfBirth.length == 1){
      setSelectBirhtPlaceBuyer(false)
    }
      console.count('useEffect count:')
      if(inputValues.buyerplaceOfBirth.length > 1){
        setSelectBirhtPlaceBuyer(true)
        
      }else if (inputValues.buyerplaceOfBirth == i){
        setSelectBirhtPlaceBuyer(false)
      }  
}
const checkValidation = () => {
  const errors = {};
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneRegex = /^.{16,}$/;
  if(!inputValues.surname){
    errors.surname = 'обязательное поле'
  }
  
  
  if(!inputValues.name){
    errors.name = 'обязательное поле'
  }
  if(!inputValues.fathersname){
    errors.fathersname = 'обязательное поле'
  }if(!inputValues.gender){
    errors.gender = 'обязательное поле'
  }
  if(!inputValues.birthday){
    errors.birthday = 'обязательное поле'
  }
  if(!inputValues.issuedBy){
    errors.issuedBy = 'обязательное поле'
  }
  if(!inputValues.dateOfIssue){
    errors.dateOfIssue = 'обязательное поле'
  }
  if(!inputValues.placeOfBirth){
    errors.placeOfBirth = 'обязательное поле'
  }
  if(!inputValues.series){
    errors.series = 'обязательное поле'
  }
  if(!inputValues.subdivision){
    errors.subdivision = 'обязательное поле'
  }
  if(!inputValues.number){
    errors.number = 'обязательное поле'
  }
  if(!inputValues.regAdress){
    errors.regAdress = 'обязательное поле'
  }
  if(!inputValues.citizenship){
    errors.citizenship = 'обязательно поле'
  }
 
  if(!inputValues.buyersurname){
    errors.buyersurname = 'обязательное поле'
  }
  
  
  if(!inputValues.buyername){
    errors.buyername = 'обязательное поле'
  }
  if(!inputValues.buyerfathersname){
    errors.buyerfathersname = 'обязательное поле'
  }if(!inputValues.buyergender){
    errors.buyergender = 'обязательное поле'
  }
  if(!inputValues.buyerbirthday){
    errors.buyerbirthday = 'обязательное поле'
  }
  if(!inputValues.buyerissuedBy){
    errors.buyerissuedBy = 'обязательное поле'
  }
  if(!inputValues.buyerdateOfIssue){
    errors.buyerdateOfIssue = 'обязательное поле'
  }
  if(!inputValues.buyerplaceOfBirth){
    errors.buyerplaceOfBirth = 'обязательное поле'
  }
  if(!inputValues.buyerseries){
    errors.buyerseries = 'обязательное поле'
  }
  if(!inputValues.buyersubdivision){
    errors.buyersubdivision = 'обязательное поле'
  }
  if(!inputValues.buyernumber){
    errors.buyernumber = 'обязательное поле'
  }
  if(!inputValues.buyerregAdress){
    errors.buyerregAdress = 'обязательное поле'
  }
  if(!inputValues.buyercitizenship){
    errors.buyercitizenship = 'обязательно поле'
  }
  if(!inputValues.purchase){
    errors.purchase = 'обязательно поле'
  }

  if(!inputValues.objectAdress){
    errors.objectAdress = 'обязательное поле'
  }
  if(!inputValues.type){
    errors.type = 'обязательное поле'
  }
  if(!inputValues.price){
    errors.price = 'обязательно поле'
  }
  if(!inputValues.cadastralNum){
    errors.cadastralNum = 'обязательно поле'
  }
  return errors
}
const handleSubmit = (e) => {
  let link =e.target.parentNode.href
  e.preventDefault();
  let errors = checkValidation(inputValues)
  setErrors(errors)
  console.log(errors)
  if(Object.keys(errors).length===0){
    window.location.href=link

  }
};
// ******
  return (
    <>
        <div className={navstyle.navbar}>
    <div className={navstyle.navbar_item_adaptive}>
        <span>1</span>
      </div>
    <div className={navstyle.navbar_dots_adaptive}>
      <div className={navstyle.navbar_dots_item}></div>
      <div className={navstyle.navbar_dots_item}></div>
      <div className={navstyle.navbar_dots_item}></div>
    </div>
    
    <div className={navstyle.navbar_item_adaptive}>
        <span>2</span>
      </div>
      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>


      <div className={navstyle.navbar_item_adaptive}>
        <span>3</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={navstyle.navbar_item_adaptive}>
        <span>4</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={ navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>
      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={ navstyle.navbar_item}>
          <span>6</span>
        </div>

      <div className={navstyle.navbar_dots}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={  navstyle.item_wrapper_active}>
        <div className={ navstyle.navbar_item_active }>
          <span>7</span>
        </div>
        <div className={ navstyle.navbar_active_section  }>
          <p className={ navstyle.active_section_text} >Ввод  данных договора</p>
        </div>
        </div>
    
        </div>
        <div className={style.container}>
            <h1 className={style.title}>Данные договора для формирования уведомления о сделке</h1>
            <div>
            <form onSubmit={handleSubmit}>
                <p className={style.step_text}>1. Договор купли-продажи</p>
                 <div className={style.purchase_wrapper}>
                 <div className={style.input_wrapper_purchase}>
          <label className={style.label}>Дата договора купли-продажи</label>
           <IMaskInput 
           autoComplete='off'
            maxLength={10}
          
            onChange={(e) => handleChange(e)}
            name='purchase'
            value={inputValues.purchase}
           className={style.input_purchase}/>
           <p className={style.error_text}>{errors.purchase}</p>
          </div>
                 </div>
                 <p className={style.step_text}>2. Данные продавца</p>

        <div className={style.wrapper}>
        <div className={style.inputs_column}>
        <div className={style.input_wrapper}>
          <label className={style.label}>Фамилия</label>
           <IMaskInput  
            autoComplete='off'
            onChange={(e) => handleChange(e)}
            name='surname'
            mask='##############################'
            value={inputValues.surname}
            definitions={{'#':/[а-яА-Я]/,}}
           className={style.input}/>
            
         
           <p className={style.error_text}>{errors.surname}</p>
          </div>
          <div className={style.input_wrapper}>
          <label className={style.label}>Имя</label>
           <IMaskInput 
           mask='###############################'
              
           definitions={{
             '#':/[а-яА-Я]/,}}
            autoComplete='off'
            onChange={(e) => handleChange(e)}
            name='name'
            value={inputValues.name}
           className={style.input} />
   
           
           <p className={style.error_text}>{errors.name}</p>
          </div>

          <div className={style.input_wrapper}>
          <label className={style.label}>Отчество</label>
           <IMaskInput 
           mask='###############################'
              
           definitions={{
             '#':/[а-яА-Я]/,}}
            autoComplete='off'
            onChange={(e) => handleChange(e)}
            name='fathersname'
            value={inputValues.fathersname}
           className={style.input} />
        
           <p className={style.error_text}>{errors.fathersname}</p>
          </div>
          <div className={style.input_wrapper}>
          <label className={style.label}>Дата рождения</label>
           <IMaskInput 
           autoComplete='off'
           mask="00.00.0000"
            onChange={handleChange}
            name='birthday'
           className={style.input}/>
           <p className={style.error_text}>{errors.birthday}</p>
          </div>



          <div className={style.input_wrapper}>
          <label className={style.label}>Пол</label>
           <div onClick={()=> setSelectGender(true)} className={style.input_gender}>
            <span style={{margin:'0 0 10px 0'}}>{inputValues.gender}</span>
           </div>
          
           <div className={selectGender ? style.custom_select_wrapper :
           style.custom_select_wrapper_disabled}>
              <option onClick={()=>{setInputValue({...inputValues, gender:'Mуж'});setSelectGender(false)}}  className={style.list_item} value="Муж">Муж</option>
              <option onClick={()=>{setInputValue({...inputValues, gender:'Жен'});setSelectGender(false)}} className={style.list_item} value="Жен">Жен</option>
          </div>
           <p className={style.error_text}>{errors.gender}</p>
          </div>

          
          <div className={style.input_wrapper}>
          <label className={style.label}>Место рождения</label>
           <IMaskInput 
           autoComplete='off'
           
            onChange={(e) => {handleChange(e);birthPlaceSelectSettings()}}
            name='placeOfBirth'
            value={inputValues.placeOfBirth}
           className={style.input}/>
           
           <p className={style.error_text}>{errors.placeOfBirth}</p>
           <div className={selectBirthPlace ? style.custom_select_wrapper :
           style.custom_select_wrapper_disabled}>
            <ul className={style.select_list}>
            {placeOfBirthOptions.map((x,index) => (
            <li onClick={()=>{birthPlaceSelect(x.value);setSelectBirhtPlace(false)} } key={index} className={style.list_item}>{x.value}</li>))}
            </ul>
          </div>
          </div>
          <div className={style.input_wrapper}>
          <label className={style.label}>Гражданство</label>
           <IMaskInput 
           autoComplete='off'
           mask='#######################################################################################'
            
           definitions={{
             '#':/[а-яА-Я]/,}}
            onChange={(e) => handleChange(e)}
            name='citizenship'
            value={inputValues.citizenship}
           className={style.input}/>
           <p className={style.error_text}>{errors.citizenship}</p>
          </div>
       
        </div>

        <div>
            <div className={style.inputs_wrapper_small}>
            <div className={style.input_wrapper}>
          <label className={style.label}>Серия</label>
           <IMaskInput 
           autoComplete='off'
           mask="00 00"
            onChange={(e) => handleChange(e)}
            name='series'
           className={style.input_small}/>
           <p className={style.error_text}>{errors.series}</p>
          </div>
          <div className={style.input_wrapper}>
          <label className={style.label}>Номер</label>
           <IMaskInput 
           autoComplete='off'
           mask="000000"
            onChange={(e) => handleChange(e)}
            name='number'
           className={style.input_small}/>
           <p className={style.error_text}>{errors.number}</p>
          </div>

          <div className={style.input_wrapper}>
          <label className={style.label}>Дата выдачи</label>
           <IMaskInput 
           autoComplete='off'
           mask="00.00.0000"
            onChange={(e) => handleChange(e)}
            name='dateOfIssue'
           className={style.input}/>
           <p className={style.error_text}>{errors.dateOfIssue}</p>
          </div>
          </div>
          <div className={style.input_wrapper_code}>
          <label className={style.label}>Код подразделения</label>
           <IMaskInput 
           autoComplete='off'
           mask="000-000"
            onChange={(e) => handleChange(e)}
            name='subdivision'
           className={style.input}/>
           <p className={style.error_text}>{errors.subdivision}</p>

          </div>
          <div className={style.input_wrapper}>
          <label className={style.label}>Кем выдан</label>
           <textarea 
           autoComplete='off'
            onChange={(e) => {handleChange(e);issuedBySelectSettings()}}
            name='issuedBy'
            style={{resize:'none'}}
            value={inputValues.issuedBy}
           className={style.input_large}/>
          <div className={selectIssuedBy ? style.custom_select_wrapper :
           style.custom_select_wrapper_disabled}>
              <ul className={style.select_list}>
              {issuedByOptions.map((x) => (
              <li onClick={()=>{issuedBySelect(x.value);setSelectIssuedBy(false)}} className={style.list_item}>{x.value}</li>))}
            </ul>
          </div>
           <p className={style.error_text}>{errors.issuedBy}</p>
          </div>
          
          
          <div className={style.input_wrapper}>
          <label className={style.label}>Адрес регистрации</label>
           <IMaskInput 
           autoComplete='off'
            onChange={(e) => {handleChange(e);regAdressSelectSettings()}}
            name='regAdress'
            style={{resize:'none'}}
              value={inputValues.regAdress}
           className={style.input_medium}/>
           <div className={selectRegAdress ? style.custom_select_wrapper :
           style.custom_select_wrapper_disabled}>
            <ul className={style.select_list}>
            {adressOptions.map((x) => (
            <li onClick={()=>{regAdressSelect(x.value);setSelectRegAdress(false)} } className={style.list_item}>{x.value}</li>))}
            </ul>
          </div>
           <p className={style.error_text}>{errors.regAdress}</p>
          </div>
        
        </div>
     
     </div>
     <p className={style.step_text}>3. Данные покупателя</p>
     <div className={style.wrapper}>
        <div className={style.inputs_column}>
        <div className={style.input_wrapper}>
          <label className={style.label}>Фамилия</label>
           <IMaskInput  
            autoComplete='off'
            onChange={(e) => handleChange(e)}
            name='buyersurname'
            mask='##############################'
            value={inputValues.buyersurname}
            definitions={{'#':/[а-яА-Я]/,}}
           className={style.input}/>
            
          
           <p className={style.error_text}>{errors.buyersurname}</p>
          </div>
          <div className={style.input_wrapper}>
          <label className={style.label}>Имя</label>
           <IMaskInput 
           mask='###############################'
              
           definitions={{
             '#':/[а-яА-Я]/,}}
            autoComplete='off'
            onChange={(e) => handleChange(e)}
            name='buyername'
            value={inputValues.buyername}
           className={style.input} />
   
         
           <p className={style.error_text}>{errors.buyername}</p>
          </div>

          <div className={style.input_wrapper}>
          <label className={style.label}>Отчество</label>
           <IMaskInput 
           mask='###############################'
              
           definitions={{
             '#':/[а-яА-Я]/,}}
            autoComplete='off'
            onChange={(e) => handleChange(e)}
            name='buyerfathersname'
            value={inputValues.buyerfathersname}
           className={style.input} />
           <p className={style.error_text}>{errors.buyerfathersname}</p>
          </div>

          <div className={style.input_wrapper}>
          <label className={style.label}>Дата рождения</label>
           <IMaskInput 
           autoComplete='off'
           mask="00.00.0000"
            onChange={handleChange}
            name='buyerbirthday'
           className={style.input}/>
           <p className={style.error_text}>{errors.buyerbirthday}</p>
          </div>



          <div className={style.input_wrapper}>
          <label className={style.label}>Пол</label>
           <div onClick={()=> setSelectGenderBuyer(true)} className={style.input_gender}>
            <span style={{margin:'0 0 10px 0'}}>{inputValues.buyergender}</span>
           </div>
          
           <div className={selectGenderBuyer ? style.custom_select_wrapper :
           style.custom_select_wrapper_disabled}>
              <option onClick={()=>{setInputValue({...inputValues, buyergender:'Mуж'});setSelectGenderBuyer(false)}}  className={style.list_item} value="Муж">Муж</option>
              <option onClick={()=>{setInputValue({...inputValues, buyergender:'Жен'});setSelectGenderBuyer(false)}} className={style.list_item} value="Жен">Жен</option>
          </div>
           <p className={style.error_text}>{errors.buyergender}</p>
          </div>

          <div className={style.input_wrapper}>
          <label className={style.label}>Место рождения</label>
           <IMaskInput 
           autoComplete='off'
          
            onChange={(e) => {handleChange(e);buyerbirthPlaceSelectSettings()}}
            name='buyerplaceOfBirth'
            value={inputValues.buyerplaceOfBirth}
           className={style.input}/>
           
           <p className={style.error_text}>{errors.buyerplaceOfBirth}</p>
           <div className={selectBirthPlaceBuyer ? style.custom_select_wrapper :
           style.custom_select_wrapper_disabled}>
            <ul className={style.select_list}>
            {buyerplaceOfBirthOptions.map((x,index) => (
            <li onClick={()=>{buyerbirthPlaceSelect(x.value);setSelectBirhtPlaceBuyer(false)} } key={index} className={style.list_item}>{x.value}</li>))}
            </ul>
          </div>
          </div>
          <div className={style.input_wrapper}>
          <label className={style.label}>Гражданство</label>
           <IMaskInput 
           autoComplete='off'
           mask='#######################################################################################'
            
           definitions={{
             '#':/[а-яА-Я]/,}}
            onChange={(e) => handleChange(e)}
            name='buyercitizenship'
            value={inputValues.buyercitizenship}
           className={style.input}/>
           <p className={style.error_text}>{errors.buyercitizenship}</p>
          </div>
          
       
        </div>

        <div>
            <div className={style.inputs_wrapper_small}>
            <div className={style.input_wrapper}>
          <label className={style.label}>Серия</label>
           <IMaskInput 
           autoComplete='off'
           mask="00 00"
            onChange={(e) => handleChange(e)}
            name='buyerseries'   
            value={inputValues.buyerseries}
         
           className={style.input_small}/>
           <p className={style.error_text}>{errors.buyerseries}</p>
          </div>
          {console.log(inputValues.buyerseries)}

          <div className={style.input_wrapper}>
          <label className={style.label}>Номер</label>
           <IMaskInput 
           autoComplete='off'
           mask="000000"
            onChange={(e) => handleChange(e)}
            name='buyernumber'
           className={style.input_small}/>
           <p className={style.error_text}>{errors.buyernumber}</p>
          </div>

          <div className={style.input_wrapper}>
          <label className={style.label}>Дата выдачи</label>
           <IMaskInput 
           autoComplete='off'
           mask="00.00.0000"
            onChange={(e) => handleChange(e)}
            name='buyerdateOfIssue'
           className={style.input}/>
           <p className={style.error_text}>{errors.buyerdateOfIssue}</p>
          </div>
          </div>
          <div className={style.input_wrapper_code}>
          <label className={style.label}>Код подразделения</label>
           <IMaskInput 
           autoComplete='off'
           mask="000-000"
            onChange={(e) => handleChange(e)}
            name='buyersubdivision'
           className={style.input}/>
           <p className={style.error_text}>{errors.buyersubdivision}</p>

          </div>
          <div className={style.input_wrapper}>
          <label className={style.label}>Кем выдан</label>
           <textarea 
           autoComplete='off'
            onChange={(e) => {handleChange(e);issuedBySelectSettingsBuyer()}}
            name='buyerissuedBy'
            style={{resize:'none'}}
            value={inputValues.buyerissuedBy}
           className={style.input_large}/>
          <div className={selectIssuedByBuyer ? style.custom_select_wrapper :
           style.custom_select_wrapper_disabled}>
              <ul className={style.select_list}>
              {buyerissuedByOptions.map((x) => (
              <li onClick={()=>{issuedBySelectBuyer(x.value);setSelectIssuedByBuyer(false)}} className={style.list_item}>{x.value}</li>))}
            </ul>
          </div>
           <p className={style.error_text}>{errors.buyerissuedBy}</p>
          </div>
          
          
          <div className={style.input_wrapper}>
          <label className={style.label}>Адрес регистрации</label>
           <IMaskInput 
           autoComplete='off'
            onChange={(e) => {handleChange(e);regAdressSelectSettingsBuyer()}}
            name='buyerregAdress'
            style={{resize:'none'}}
              value={inputValues.buyerregAdress}
           className={style.input_medium}/>
           <div className={selectRegAdressBuyer ? style.custom_select_wrapper :
           style.custom_select_wrapper_disabled}>
            <ul className={style.select_list}>
            {buyerregAdressOptions.map((x) => (
            <li onClick={()=>{regAdressSelectBuyer(x.value);setselectRegAdressBuyer(false)} } className={style.list_item}>{x.value}</li>))}
            </ul>
          </div>
           <p className={style.error_text}>{errors.buyerregAdress}</p>
          </div>
        
        </div>
     
     </div>
     <p className={style.step_text}>4. Тип объекта</p>
     <div className={style.input_wrapper_purchase}>
           <div onClick={()=> setSelectType(true)} className={style.input_gender}>
            <span style={{margin:'0 0 10px 0'}}>{inputValues.type}</span>
           </div>
          
           <div className={selectType ? style.custom_select_wrapper :
           style.custom_select_wrapper_disabled}>
              <option onClick={()=>{setInputValue({...inputValues, type:'Квартира'});setSelectType(false)}}  className={style.list_item} value="Квартира">Квартира</option>
              <option onClick={()=>{setInputValue({...inputValues, type:'Комната'});setSelectType(false)}} className={style.list_item} value="Комната">Комната</option>
              <option onClick={()=>{setInputValue({...inputValues, type:'Дом'});setSelectType(false)}} className={style.list_item} value="Дом">Дом</option>
              <option onClick={()=>{setInputValue({...inputValues, type:'Земельный участок'});setSelectType(false)}} className={style.list_item} value="Земельный участок">Земельный участок</option>


          </div>
           <p className={style.error_text}>{errors.type}</p>
          </div>

     <p className={style.step_text}>5. Кадастровый номер объекта</p>
     <div className={style.input_wrapper_purchase}>
           <IMaskInput 
           autoComplete='off'
           mask='00:00:0000000:0000'
           
           
            onChange={(e) => handleChange(e)}
            name='cadastralNum'
            value={inputValues.cadastralNum}
           className={style.input_purchase}/>
           <p className={style.error_text}>{errors.cadastralNum}</p>
          </div>
     <p className={style.step_text}>6.  Адрес объекта</p>
     <div className={style.input_wrapper_purchase}>
           <IMaskInput 
           autoComplete='off'
            onChange={(e) => {handleChange(e);objectAdressSelectSettings()}}
            name='objectAdress'
            style={{resize:'none'}}
            value={inputValues.objectAdress}
           className={style.input_purchase}/>
          <div className={objectAdressSelect ? style.custom_select_wrapper :
           style.custom_select_wrapper_disabled}>
              <ul className={style.select_list}>
              {objectAddOptions.map((x) => (
              <li onClick={()=>{objectAddSelect(x.value);setObjectAdressSelect(false)}} className={style.list_item}>{x.value}</li>))}
            </ul>
          </div>
           <p className={style.error_text}>{errors.objectAdress}</p>
          </div>
     <p className={style.step_text}>7.  Цена сделки по договору</p>
     <div className={style.input_wrapper_purchase}>
           <IMaskInput 
           autoComplete='off'
           mask='0000000000000000'
           
           
            onChange={(e) => handleChange(e)}
            name='price'
            value={inputValues.price}
           className={style.input_purchase}/>
           <p className={style.error_text}>{errors.price}</p>
          </div>


     <div className={style.button_wrapper}>
        <Link onClick={(e)=>handleSubmit(e)} to='/yourcentersecond' className={style.button}>
        <button type='submit' className={style.button}>Далее</button>
        </Link>
      </div>
      
     </form>
            </div>
        </div>
    </>
  )
}
