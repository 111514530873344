import axios from "axios"
import React from "react"
import style from "./styles.module.css"
import urls from "../../urls.json"
import { useState } from "react"
import { useEffect } from "react"
import Moonloader from 'react-spinners/MoonLoader'
import navstyle from '../../components/navbar/navbar.module.css'
import ModalWindow from "../../components/modal/ModalWindow"

export default function PassportLoader() {
  const id = localStorage.getItem("id")
  const [isModal, setModal] = useState(false)
  const [message, setMessage] = useState("")
  async function LoadDocuments() {

    await axios.get(`${urls.url}/api/itmonitoring/documents/upload?id=${id}`)
      .then(response => {
        window.location.href = "/itmonitoring2fa"
      })
      .catch(error => {
        setModal(true)
        setMessage(error.response.data)
      })
  }
  useEffect(() => {

    LoadDocuments()
  }, [])
  return (
    <React.Fragment>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item}>
          <span>5</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>6</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Проверка анкеты</p>
          </div>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>
      <div className={style.container}>
        <div className={style.loader_container}>
          <ModalWindow isActive={isModal} text={message} onClose={() => window.location.href = "/editdata"} />

          <Moonloader className={style.loader} color="#69CD7C" size={160} speedMultiplier={0.5}></Moonloader>
          <span className={style.loader_text}>Минуточку...</span>
        </div>
        <div className={style.text_wrapper}>
          <h2 className={style.title}>ИДЁТ ПРОВЕРКА ДАННЫХ В УЦ</h2>
          <p className={style.subtitle}>ЭТО ЗАЙМЁТ НЕ БОЛЕЕ 15 МИНУТ</p>
        </div>
      </div>
    </React.Fragment>
  );
}