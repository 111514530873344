import axios from "axios";
import React, { useEffect } from "react";
import style from './2fa.module.css'
import { useState } from "react";
import urls from "../../urls.json"
import ModalWindow from '../../components/modal/ModalWindow';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import navstyle from "../../components/navbar/navbar.module.css";
import step1 from "./img/step1.jpg"
import step2 from "./img/step2.jpg"
import { Link } from "react-router-dom";

export default function ItMonitoring2fa() {
  const id = localStorage.getItem("id")
  const [isLoader, setLoader] = useState(false)
  const [message, setMessage] = useState("")
  const [isModal, setModal] = useState(false)

  async function Next() {
    setLoader(true)
    await axios.get(`${urls.url}/api/itmonitoring/blank?id=${id}`)
      .then(response => {
        window.location.href = "/delivery"
      })
      .catch(error => {
        setMessage(error.response.data)
        setModal(true)
        setLoader(false)
      })
    setLoader(false)
  }
  return (
    <React.Fragment>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>5</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Подключение MYDSS</p>
          </div>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>6</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>
      </div>
      <div className={style.container}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ModalWindow isActive={isModal} text={message} onClose={() => setModal(false)} />
        <div className={style.wrapper}>
          <div className={style.steps_block}>
            <div className={style.step}>
              <span>1. Обновите страницу в приложении MyDSS (потяните экран вниз)</span>
            </div>
            <div className={style.step}>
              <span>2. Подтвердите создание профиля в приложении MyDSS</span>
              <img src={step1} />
            </div>
            <div className={style.step}>
              <span>3. Выпустите сертификат</span>
              <img src={step2} />
            </div>
            <div className={style.step}>
              <span>Нажмите кнопку "Далее"</span>
            </div>
          </div>
          <div className={style.button_wrapper}>
            <button className={style.button} onClick={() => Next()}>Далее</button>
          </div>
          <div className={style.button_wrapper} style={{marginTop:15}}>
          <Link style={{backgroundColor:"red"}} className={style.button} to='/itmonitoring'>Назад</Link>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
}