import React, { useEffect } from 'react'
import { useState } from 'react';
import style from './courier.module.css'
import courierImg from '../../images/cargoMan.svg'
import selectArrow from '../../images/selectArrow.svg'
import { IMaskInput } from 'react-imask';
import { Link } from 'react-router-dom';
import axios from 'axios';
import urls from '../../urls.json'
import ModalWindow from '../../components/modal/ModalWindow';
import { Backdrop, CircularProgress } from '@mui/material';

export default function CallCourier() {
  const [errors, setErrors] = useState({})
  const [selectInterval, setSelectInterval] = useState(false)
  const [deliverySelect, setDeliverySelect] = useState(false)
  const [isModal, setModal] = useState(false)
  const [modalText, setModalText] = useState("")
  const [adressOptions, setAdressOptions] = useState([])
  const [isLoader, setLoader] = useState(false)
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState("")
  const [date, setDate] = useState("")
  const selectItems = [
    'Сергеевич',
    'Пушкин',
    'Александр',
  ]
  async function createDelivery() {
    setLoader(true)
    await axios.post(`${urls.url}/api/user/delivery`, {
      userId: localStorage.getItem("id"),
      deliveryDate: date,
      address: address,
      time: inputValues.interval,
      phoneNumber: phone
    }).then(response => {
      window.location.href = `/track/${response.data}`
    }).catch(error => {
      setModal(true)
      setModalText(error.response.data)
      setLoader(false)
    })
  }
  const [inputValues, setInputValue] = useState({
    phoneNumber: '',
    date: '',
    deliveryAddress: '',
    interval: '',
  })
  function handleChange(event) {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  const checkValidation = () => {
    const errors = {};
    const phoneRegex = /^.{16,}$/;
    const [day, month, year] = date.split('.')

    if (!phone) {
      errors.phone = 'обязательное поле'
    }
    else if (phone.length < 17) {
      errors.phone = 'неверный формат'
    }
    if (!address) {
      errors.address = 'обязательное поле'
    }
    if (!date) {
      errors.date = 'обязательное поле'
    }
    if (!inputValues.interval) {
      errors.interval = 'обязательное поле'
    }
    if (day > 31) {
      errors.date = 'неверный формат'
    }
    if (month > 12) {
      errors.date = 'неверный формат'
    }
    if (year < 1900) {
      errors.date = 'неверный формат'
    }
    return errors
  }
  function regAdressSelectSettings(regAdress) {
    const i = selectItems.map((x) => (
      x));
    if (address.length == 1) {
      console.log('its working')
      setDeliverySelect(false)
    }
    console.count('useEffect count:')
    if (address.length > 1) {
      setDeliverySelect(true)
    } else if (address == i) {
      setDeliverySelect(false)
    }
  }
  function deliverySelectOptions(deliveryAddress) {
    setAddress(deliveryAddress)
    setDeliverySelect(false)
  }
  async function handleSubmit() {
    let errors = checkValidation()
    setErrors(errors)
    if (Object.keys(errors).length === 0) {
      await createDelivery();
    }
  };

  useEffect(() => {
    axios.get(`${urls.url}/api/dadata/address?query=${address}`)
      .then(function (response) {
        console.log(response)
        setAdressOptions(response.data)
      }).catch(error => {
        setAdressOptions([{ value: "Ничего не найдено" }])
      })
  }, [address])
  return (
    <div className={style.container}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalWindow isActive={isModal} text={modalText} onClose={() => setModal(false)} />

      <div className={style.title_wrapper}>
        <img className={style.title_img} src={courierImg} alt="" />
        <h1 className={style.title}>Подтверждение личности через курьера</h1>
      </div>
      <div className={style.courier_content_wrapper}>
        <p className={style.courier_text}>
          В поле ниже введите адрес, по которому должен приехать курьер для идентификации личности. После ввода адреса выберите доступную дату приезда и временной интервал.</p>
        <p className={style.courier_text}>
          Курьер привезет в запечатанном конверте заявление на выпуск электронной подписи и идентифицирует вашу личность для удостоверяющего центра</p>
      </div>
      <div className={style.inputs_container}>
        <div className={style.input_wrapper}>
          <label className={style.label}>Адрес доставки</label>
          <textarea
            value={address}
            onChange={(e) => { setAddress(e.target.value); regAdressSelectSettings() }}
            className={style.text_area} />
          <div className={deliverySelect ? style.custom_select_wrapper :
            style.custom_select_wrapper_disabled}>
            <ul className={style.select_list}>
              {adressOptions.map((x, index) => (
                <li onClick={() => { deliverySelectOptions(x.value); setDeliverySelect(false) }} key={index} className={style.list_item}>{x.value}</li>))}
            </ul>
          </div>
          <p className={style.error_text_long}>{errors.address}</p>
        </div>
        <div className={style.input_wrapper_phone}>
          <label className={style.label}>Номер телефона</label>
          <IMaskInput
            value={phone}
            onAccept={(value) => setPhone(value)}
            className={style.input_phone}
            placeholder='+7(000) 000-00-00'
            mask="+7(000) 000-00-00" />
          <p className={style.error_text}>{errors.phone}</p>
        </div>
        <div className={style.date_inputs_wrapper}>
          <div className={style.input_wrapper}>
            <label className={style.label}>Дата</label>
            <IMaskInput
              onAccept={(value) => setDate(value)}
              value={date}
              className={style.input_phone}
              placeholder='дд.мм.гггг'
              mask="##.##.####"
              definitions={{
                '#': /[0-9]/,
              }}
            />
            <p className={style.error_text}>{errors.date}</p>
          </div>
          <div className={style.input_wrapper}>
            <label className={style.label}>Интервал</label>
            <div className={style.input_interval} onClick={() => setSelectInterval(true)}>
              <span style={{ margin: '0 0 10px 0' }}>{inputValues.interval}</span>
              <img className={selectInterval ? style.select_arrow_disabled : style.select_arrow_active} src={selectArrow} alt="" />
            </div>
            <div className={selectInterval ? style.custom_select_wrapper :
              style.custom_select_wrapper_disabled}>
              <ul className={style.select_list}>
                <li
                  onClick={() => { setInputValue({ ...inputValues, interval: '10:00-14:00' }); setSelectInterval(false) }}
                  className={style.list_item}>10:00-14:00</li>
                <li
                  onClick={() => {
                    setInputValue({ ...inputValues, interval: '14:00-20:00' });
                    setSelectInterval(false)
                  }}
                  className={style.list_item}>14:00-20:00</li>
                <li
                  onClick={() => {
                    setInputValue({ ...inputValues, interval: '10:00-20:00' });
                    setSelectInterval(false)
                  }}
                  className={style.list_item}>10:00-20:00</li>
              </ul>
            </div>
            <p className={style.error_text}>{errors.interval}</p>
          </div>
        </div>
      </div>
      <div className={style.button_container}>
        <button onClick={() => handleSubmit()} className={style.button}>Далее</button>
      </div>
    </div>
  )
}
