import axios from "axios"
import React from "react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import urls from "./urls.json"
export default function UserMiddleware()
{
    const {id} =useParams()
    async function GetData(){
        await axios.get(`${urls.url}/api/user?id=${id}`).then(response=>{
            if (localStorage.getItem("id") != response.data.id) {
                localStorage.clear()
              }
            localStorage.setItem("id",id)
            localStorage.setItem("center",response.data.center)
            window.location.href=`/${response.data.status}`
        })
    }
    useEffect(()=>{
        GetData();
    },[])
    return(<></>)
}