import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import style from './snils.module.css'
import snilsImg from '../../images/snils.svg'
import { IMaskInput } from 'react-imask';
import navstyle from '../../components/navbar/navbar.module.css'
import urls from '../../urls.json'
import ModalWindow from '../../components/modal/ModalWindow';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

export default function Snils() {
  // validation

  const [snils, setSnils] = useState("")
  const [errors, setErrors] = useState()
  const [modalText, setModalText] = useState('ЗАГРУЗКА ДАННЫХ НЕ УДАЛАСЬ ПО ПРИЧИНЕ ТАКОЙ-ТО')
  const [modal, setModal] = useState(false)
  const [isLoader, setLoader] = useState(false)
  const HandleSubmit = () => {
    let errors = Validate(snils)
    UpdateSnils()
  }
  useEffect(()=>{
    GetSnils()
  },[])
  const Validate = (snils) => {
    let error = ''
    const regex = /^.{13,}$/;
    if (!snils) {
      error = 'обязательное поле'
    } else if (!regex.test(snils)) {
      error = 'неверный формат'
    }
    setErrors(error)
    return error;
  }
  async function UpdateSnils() {
    setLoader(true)
    const body = {
      "id": localStorage.getItem("id"),
      "snils": snils
    }
    await axios.put(`${urls.url}/api/user/snils`, body)
      .then(response => {
        if(response.status===200){
        window.location.href = "/inn"}
      }).catch(error => {
        console.log("request is unsuccsesful")
      });
      setLoader(false)
  }
  async function GetSnils() {
    setLoader(true)
    await axios.get(`${urls.url}/api/idx/snils?id=${localStorage.getItem("id")}`).then(response => {
      setSnils(response.data.number.replace(" ", "-"))
    }).catch(error => {
    })
    setLoader(false)
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalWindow isActive={modal} text={modalText} onClose={() => setModal(false)} />
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>3</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Ввод данных СНИСЛ</p>
          </div>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>6</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>
      <div className={style.container}>

        <div>
          <div className={style.manual_snils_wrapper}>
            <img src={snilsImg} className={style.img} ></img>
            <p className={style.manual_snils_text}>Введите ваш СНИЛС</p>

            <div className={style.input_wrapper}>
              <label className={style.label}>СНИЛС</label>
              <IMaskInput
                className={style.input}
                radix="."
                value={snils}
                mask={"000-000-000-00"}
                onAccept={
                  (value) => setSnils(value)
                }
                placeholder=''
              />

              <p className={style.error_text}>{errors}</p>
            </div>
          </div>

          <div className={style.snils_text_wrapper}>
            <p style={{ textAlign: 'center' }}>Нет СНИЛС или ИНН? Ознакомиться с инструкцией о получении в разделе <Link className={style.faq_link} to='/faq'>F.A.Q.</Link></p>
          </div>

          <div className={style.button_wrapper} style={{ marginBottom: '20px' }}>
            <button onClick={() => HandleSubmit()} className={style.button}>Далее</button>
          </div>
          <div className={style.button_wrapper} >
            <Link style={{ backgroundColor: "red" }} to={"/filldata"} className={style.button}>Назад</Link>
          </div>
        </div>
      </div>
    </>

  )
}
