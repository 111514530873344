import React from 'react'
import { Link } from 'react-router-dom'
import style from './citizenPassport.module.css'
import navstyle from '../../components/navbar/navbar.module.css'
import { useNavigate } from 'react-router-dom';

import foreignerImg from '../../images/foreigner.svg'
import upload from '../../images/upload.svg'
export default function СitizenPassportUpload() {
  const navigate = useNavigate();
  const link = localStorage.getItem('link')

  return (
    <>
     <div className={navstyle.navbar}>
    <div className={navstyle.navbar_item}>
        <span>1</span>
      </div>
    <div className={navstyle.navbar_dots}>
      <div className={navstyle.navbar_dots_item}></div>
      <div className={navstyle.navbar_dots_item}></div>
      <div className={navstyle.navbar_dots_item}></div>
    </div>
    <div className={  navstyle.item_wrapper_active}>
        <div className={ navstyle.navbar_item_active }>
          <span>2</span>
        </div>
        <div className={ navstyle.navbar_active_section  }>
          <p className={ navstyle.active_section_text} >Ввод паспортных данных</p>
        </div>
        </div>
      
    
      

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>


      <div className={navstyle.navbar_item_adaptive}>
        <span>3</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={navstyle.navbar_item_adaptive}>
        <span>4</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={navstyle.navbar_item_adaptive}>
        <span>5</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={navstyle.navbar_item_adaptive}>
        <span>6</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={navstyle.navbar_item_adaptive}>
        <span>7</span>
      </div>
    
</div>
     <div className={style.container}>
        <div className={style.title_wrapper}>
            <img src={foreignerImg} alt="" />
            <h1 className={style.title}>Загрузите страницы паспорта для автораспознавания или заполните данные вручную</h1>
        </div>

        <div className={style.inputs_container}>
            <div className={style.input_frame}>
                <label for='1'>Разворот с фото</label>
                <img className={style.upload_img} src={upload} alt="" />
                <div className={style.text_wrapper}>
                    <p style={{color:'#C1D3DC'}}>Перетащите сюда или выберете</p>
                    <p style={{color:'#69CD7C'}}>файлы с компьютера</p>
                    <p style={{color:'#C1D3DC'}}>Сканы или четкие фотографии. Допустимые форматы: pdf, jpg, jpeg, png. Размер файла не более 10Мб</p>
                </div>
                <input className={style.input} id='1' type="file" placeholder='0' />
            </div>
            
        </div>
        <div className={style.buttons_wrapper}>
            <div>
            <Link to='/preloader'>

                <button className={style.button_forward}>Далее</button>
                </Link>

            </div>
            <div>
              
            <div>
                <a href={link}   style={{marginTop:'20px',backgroundColor:"red"}}  className={style.button_handwrite}>Назад</a>

            </div>
            </div>
        </div>
    </div>
    </>
   
  )
}