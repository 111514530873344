import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom'
import style from './manualdata.module.css'
import foreignerPassport from '../../images/foreignerPassport.svg'
import { IMaskInput } from 'react-imask';
import navstyle from '../../components/navbar/navbar.module.css'
import { useEffect } from 'react'
import urls from '../../urls.json'
import axios from 'axios';

export default function ForeignerManualDataFill() {
  const navigate = useNavigate();

  const [regionCode, setRegionCode] = useState([])
  const [adressOptions, setAdressOptions] = useState([])
  const [issuedByOptions, setIssuedByOptions] = useState([])
  const [placeOfBirthOptions, setPlaceOfBirthOptions] = useState([])
  // НОВАЯ ВАЛИДАЦИЯ + Селект
  const [selectSurname, setSelectSurname] = useState(false)
  const [selectName, setSelectName] = useState(false)
  const [selectFathersname, setSelectFathersname] = useState(false)
  const [selectGender, setSelectGender] = useState(false)
  const [selectBirthPlace, setSelectBirhtPlace] = useState(false)
  const [selectRegAdress, setSelectRegAdress] = useState(false)
  const [selectIssuedBy, setSelectIssuedBy] = useState(false)
  const [selectCitizenship, setCitizenshipSelect] = useState(false)
  const selectItems = [
    'Сергеевич',
    'Пушкин',
    'Александр',
  ]
  const [inputValues, setInputValue] = useState({
    surname: localStorage.getItem("surname"),
    name: localStorage.getItem("name"),
    fathersname: localStorage.getItem("fathersname"),
    phoneNumber: '',
    birthday: '',
    gender: '',
    placeOfBirth: '',
    issuedBy: '',
    dateOfIssue: '',
    series: '',
    number: '',
    subdivision: '',
    citizenship: '',
    regAdress: '',
  })

  useEffect(() => {
    localStorage.setItem("surname", inputValues.surname)
    localStorage.setItem("name", inputValues.name)
    localStorage.setItem("fathersname", inputValues.fathersname)
    localStorage.setItem("phoneNumber", inputValues.phoneNumber)
    localStorage.setItem("birthday", inputValues.birthday)
    localStorage.setItem("gender", inputValues.gender)
    localStorage.setItem("placeOfBirth", inputValues.placeOfBirth)
    localStorage.setItem("issuedBy", inputValues.issuedBy)
    localStorage.setItem("dateOfIssue", inputValues.dateOfIssue)
    localStorage.setItem("series", inputValues.series)
    localStorage.setItem("number", inputValues.number)
    localStorage.setItem("subdivision", inputValues.subdivision)
    localStorage.setItem("citizenship", inputValues.citizenship)
    localStorage.setItem("regAdress", inputValues.regAdress)


  }, [inputValues])
  useEffect(() => {
    axios.get(`${urls.url}/api/dadata/address?query=${inputValues.regAdress}`)
      .then(function (response) {
        console.log(response)
        setAdressOptions(response.data)
        setRegionCode(response.data.map((x) => (x.cladr)))
        if (response.data.length == 0) {
          setAdressOptions([{ value: 'Ничего не найдено' }])
        }
      }).catch(function (error) {
        setAdressOptions([{ value: 'Ничего не найдено' }])

      })
  }, [inputValues.regAdress])

  useEffect(() => {
    axios.get(`${urls.url}/api/dadata/address?query=${inputValues.placeOfBirth}`)
      .then(function (response) {
        console.log(response)
        setPlaceOfBirthOptions(response.data)
        if (response.data.length == 0) {
          setPlaceOfBirthOptions([{ value: 'Ничего не найдено' }])
        }
      }).catch(function (error) {
        setPlaceOfBirthOptions([{ value: 'Ничего не найдено' }])

      })
  }, [inputValues.placeOfBirth])

  useEffect(() => {
    localStorage.setItem("isForeigner", 1)
  }, [])
  const [errors, setErrors] = useState({})
  function handleChange(event) {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  // handle select
  function citizenshipSelect(citizenship) {
    setInputValue({ ...inputValues, citizenship: citizenship })
    setCitizenshipSelect(false)
  }
  function surnameSelect(surname) {
    setInputValue({ ...inputValues, surname: surname })
    setSelectSurname(false)
  }
  function nameSelect(name) {
    setInputValue({ ...inputValues, name: name })
    setSelectName(false)
  }
  function fathersnameSelect(fathersname) {
    setInputValue({ ...inputValues, fathersname: fathersname })
    setSelectFathersname(false)
  }
  function birthPlaceSelect(placeOfBirth) {
    setInputValue({ ...inputValues, placeOfBirth: placeOfBirth })
    setSelectFathersname(false)
  }
  function regAdressSelect(regAdress) {
    setInputValue({ ...inputValues, regAdress: regAdress })
    setSelectRegAdress(false)
  }
  function issuedBySelect(issuedBy) {
    setInputValue({ ...inputValues, issuedBy: issuedBy })
    setSelectIssuedBy(false)
  }
  useEffect(() => {
    axios.get(`${urls.url}/api/dadata/address?query=${inputValues.regAdress}`)
      .then(function (response) {
        console.log(response)
        setInputValue({ ...inputValues, regAdress: response.data[0].value })
      }).catch(function (error) { })
  }, [])
  useEffect(() => {
    axios.get(`${urls.url}/api/dadata/address?query=${inputValues.placeOfBirth}`)
      .then(function (response) {
        console.log(response)
        setInputValue({ ...inputValues, placeOfBirth: response.data[0].value })
      }).catch(function (error) { })
  }, [])
  // ***
  function issuedBySelectSettings(issuedBy) {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.issuedBy.length == 1) {
      console.log('its working')
      setSelectIssuedBy(false)
    }
    console.count('useEffect count:')
    if (inputValues.issuedBy.length > 1) {
      setSelectIssuedBy(true)

    } else if (inputValues.issuedBy == i) {
      setSelectIssuedBy(false)
    }
  }
  function regAdressSelectSettings(regAdress) {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.regAdress.length == 1) {
      console.log('its working')
      setSelectRegAdress(false)
    }
    console.count('useEffect count:')
    if (inputValues.regAdress.length > 1) {
      setSelectRegAdress(true)

    } else if (inputValues.regAdress == i) {
      setSelectRegAdress(false)
    }
  }
  function nameSelectSettings(name) {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.name.length == 1) {
      console.log('its working')
      setSelectName(false)
    }
    console.count('useEffect count:')
    if (inputValues.name.length > 1) {
      setSelectName(true)

    } else if (inputValues.name == i) {
      setSelectName(false)
    }
  }
  function surnameSelectSettings(surname) {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.surname.length == 1) {
      console.log('its working')
      setSelectSurname(false)
    }
    console.count('useEffect count:')
    if (inputValues.surname.length > 1) {
      setSelectSurname(true)

    } else if (inputValues.surname == i) {
      setSelectSurname(false)
    }
  }
  function fathersnameSelectSettings(fathersname) {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.fathersname.length == 1) {
      console.log('its working')
      setSelectFathersname(false)
    }
    console.count('useEffect count:')
    if (inputValues.fathersname.length > 1) {
      setSelectFathersname(true)

    } else if (inputValues.fathersname == i) {
      setSelectFathersname(false)
    }
  }
  function birthPlaceSelectSettings(placeOfBirth) {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.placeOfBirth.length == 1) {
      console.log('its working')
      setSelectBirhtPlace(false)
    }
    console.count('useEffect count:')
    if (inputValues.placeOfBirth.length > 1) {
      setSelectBirhtPlace(true)

    } else if (inputValues.placeOfBirth == i) {
      setSelectBirhtPlace(false)
    }
  }
  function citizenshipSelectSettings(citizenship) {
    const i = selectItems.map((x) => (
      x));
    if (inputValues.citizenship.length == 1) {
      console.log('its working')
      setCitizenshipSelect(false)
    }
    console.count('useEffect count:')
    if (inputValues.citizenship.length > 1) {
      setCitizenshipSelect(true)

    } else if (inputValues.citizenship == i) {
      setCitizenshipSelect(false)
    }
  }
  const checkValidation = () => {
    const errors = {};
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^.{16,}$/;
    const [day, month, year] = inputValues.birthday.split('.')
    const [dayOfIssue, monthOfIssue, yearOfissue] = inputValues.dateOfIssue.split('.')
    if (!inputValues.surname) {
      errors.surname = 'обязательное поле'
    }
    if (!inputValues.phoneNumber) {
      errors.phoneNumber = 'обязательное поле'
    } else if (!phoneRegex.test(inputValues.phoneNumber)) {
      errors.phoneNumber = 'неверный формат'
    }
    if (!inputValues.mail) {
      errors.mail = 'обязательное поле'
    } else if (!regex.test(inputValues.mail)) {
      errors.mail = 'неверный формат'
    }
    if (!inputValues.name) {
      errors.name = 'обязательное поле'
    }
    if (!inputValues.fathersname) {
      errors.fathersname = 'обязательное поле'
    } if (!inputValues.gender) {
      errors.gender = 'обязательное поле'
    }
    if (!inputValues.birthday) {
      errors.birthday = 'обязательное поле'
    }
    if (!inputValues.issuedBy) {
      errors.issuedBy = 'обязательное поле'
    }
    if (!inputValues.dateOfIssue) {
      errors.dateOfIssue = 'обязательное поле'
    }
    if (inputValues.dateOfIssue.length < 10) {
      errors.dateOfIssue = 'неверный формат'
    }
    if (inputValues.birthday.length < 10) {
      errors.birthday = 'неверный формат'
    }
    if (!inputValues.placeOfBirth) {
      errors.placeOfBirth = 'обязательное поле'
    }
    if (!inputValues.series) {
      errors.series = 'обязательное поле'
    }
    if (!inputValues.subdivision) {
      errors.subdivision = 'обязательное поле'
    }
    if (!inputValues.number) {
      errors.number = 'обязательное поле'
    }
    if (!inputValues.regAdress) {
      errors.regAdress = 'обязательное поле'
    } if (!inputValues.citizenship) {
      errors.citizenship = 'обязательное поле'
    }
    if (day > 31) {
      errors.birthday = 'неверный формат'
    }
    if (month > 12) {
      errors.birthday = 'неверный формат'
    }
    if (year < 1900) {
      errors.birthday = 'неверный формат'
    }
    if (dayOfIssue > 31) {
      errors.dateOfIssue = 'неверный формат'
    }
    if (monthOfIssue > 12) {
      errors.dateOfIssue = 'неверный формат'
    }
    if (yearOfissue < 1900) {
      errors.dateOfIssue = 'неверный формат'
    }
    return errors
  }
  const handleSubmit = (e) => {
    let link = e.target.parentNode.href
    e.preventDefault();
    let errors = checkValidation(inputValues)
    setErrors(errors)

    const body = {
      "id": localStorage.getItem("id"),
      "regionCode": regionCode[0],
      "patronymic": inputValues.fathersname,
      "name": inputValues.name,
      "surname": inputValues.surname,
      "serial": inputValues.series,
      "number": inputValues.number,
      "regAddress": inputValues.regAdress,
      "birthPlace": inputValues.placeOfBirth,
      "birthDate": inputValues.birthday,
      "issueDate": inputValues.dateOfIssue,
      "subDivisionCode": inputValues.subdivision,
      "subDivisionAddress": inputValues.issuedBy,
      "gender": inputValues.gender,
      "citizenship": inputValues.citizenship,
    }

    axios.put(`${urls.url}/api/user/update`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          window.location.href = link
        } else {
          console.log("request is unsuccsesful")

        }
      }).catch(function (error) {
        console.log("request is unsuccsesful")
      });
  };

  // *********

  return (
    <>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>2</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Ввод паспортных данных</p>
          </div>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>6</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>
      <div className={style.container}>
        <div className={style.title_wrapper}>
          <img src={foreignerPassport} alt="" />
          <h1 className={style.title}>Введите данные паспорта иностранного гражданина</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={style.wrapper}>
            <div className={style.inputs_column}>
              <div className={style.input_wrapper}>
                <label className={style.label}>Фамилия</label>
                <IMaskInput
                  autoComplete='off'
                  onChange={(e) => handleChange(e)}
                  name='surname'
                  mask='##############################'
                  value={inputValues.surname}
                  definitions={{ '#': /[а-яА-Я]/, }}
                  className={style.input} />

                <p className={style.error_text}>{errors.surname}</p>
              </div>
              <div className={style.input_wrapper}>
                <label className={style.label}>Имя</label>
                <IMaskInput
                  mask='###############################'

                  definitions={{
                    '#': /[а-яА-Я]/,
                  }}
                  autoComplete='off'
                  onChange={(e) => handleChange(e)}
                  name='name'
                  value={inputValues.name}
                  className={style.input} />


                <p className={style.error_text}>{errors.name}</p>
              </div>

              <div className={style.input_wrapper}>
                <label className={style.label}>Отчество</label>
                <IMaskInput
                  mask='###############################'

                  definitions={{
                    '#': /[а-яА-Я]/,
                  }}
                  autoComplete='off'
                  onChange={(e) => handleChange(e)}
                  name='fathersname'
                  value={inputValues.fathersname}
                  className={style.input} />

                <p className={style.error_text}>{errors.fathersname}</p>
              </div>
              <div className={style.input_wrapper}>
                <label className={style.label}>Дата рождения</label>
                <IMaskInput
                  autoComplete='off'
                  mask="00.00.0000"
                  onChange={handleChange}
                  name='birthday'
                  value={inputValues.birthday}
                  className={style.input} />
                <p className={style.error_text}>{errors.birthday}</p>
              </div>


              <OutsideClickHandler onOutsideClick={() => setSelectGender(false)}>
                <div className={style.input_wrapper}>
                  <label className={style.label}>Пол</label>
                  <div onClick={() => setSelectGender(true)} className={style.input_gender}>
                    <span style={{ margin: '0 0 10px 0' }}>{inputValues.gender}</span>
                  </div>

                  <div className={selectGender ? style.custom_select_wrapper :
                    style.custom_select_wrapper_disabled}>
                    <p onClick={() => { setInputValue({ ...inputValues, gender: 'Mужской' }); setSelectGender(false) }} className={style.list_item} value="Мужской">Мужской</p>
                    <p onClick={() => { setInputValue({ ...inputValues, gender: 'Женский' }); setSelectGender(false) }} className={style.list_item} value="Женский">Женский</p>
                  </div>
                  <p className={style.error_text}>{errors.gender}</p>
                </div>
              </OutsideClickHandler>


              <div className={style.input_wrapper}>
                <label className={style.label}>Место рождения</label>
                <IMaskInput
                  autoComplete='off'

                  onChange={(e) => handleChange(e)}
                  name='placeOfBirth'
                  value={inputValues.placeOfBirth}
                  className={style.input} />

                <p className={style.error_text}>{errors.placeOfBirth}</p>
              </div>
              <div className={style.input_wrapper}>
                <label className={style.label}>Гражданство</label>
                <IMaskInput
                  autoComplete='off'
                  mask='#######################################################################################'

                  definitions={{
                    '#': /[а-яА-Я]/,
                  }}
                  onChange={(e) => handleChange(e)}
                  name='citizenship'
                  value={inputValues.citizenship}
                  className={style.input} />

                <p className={style.error_text}>{errors.citizenship}</p>
              </div>
            </div>

            <div>
              <div className={style.inputs_wrapper_small}>
                <div className={style.input_wrapper}>
                  <label className={style.label}>Серия</label>
                  <IMaskInput
                    autoComplete='off'
                    mask="00 00"
                    value={inputValues.series}
                    onChange={(e) => handleChange(e)}
                    name='series'
                    className={style.input_small} />
                  <p className={style.error_text}>{errors.series}</p>
                </div>
                <div className={style.input_wrapper}>
                  <label className={style.label}>Номер</label>
                  <IMaskInput
                    autoComplete='off'
                    mask="000000"
                    onChange={(e) => handleChange(e)}
                    name='number'
                    value={inputValues.number}
                    className={style.input_small} />
                  <p className={style.error_text}>{errors.number}</p>
                </div>

                <div className={style.input_wrapper}>
                  <label className={style.label}>Дата выдачи</label>
                  <IMaskInput
                    autoComplete='off'
                    mask="00.00.0000"
                    onChange={(e) => handleChange(e)}
                    name='dateOfIssue'
                    value={inputValues.dateOfIssue}
                    className={style.input} />
                  <p className={style.error_text}>{errors.dateOfIssue}</p>
                </div>
              </div>
              <div className={style.input_wrapper_code}>
                <label className={style.label}>Код подразделения</label>
                <IMaskInput
                  autoComplete='off'
                  mask="000-000"
                  onChange={(e) => handleChange(e)}
                  name='subdivision'
                  className={style.input} />
                <p className={style.error_text}>{errors.subdivision}</p>

              </div>
              <div className={style.input_wrapper}>
                <label className={style.label}>Кем выдан</label>
                <textarea
                  autoComplete='off'
                  onChange={(e) => handleChange(e)}
                  name='issuedBy'
                  style={{ resize: 'none' }}
                  value={inputValues.issuedBy}
                  className={style.input_large} />

                <p className={style.error_text}>{errors.issuedBy}</p>
              </div>
              <OutsideClickHandler onOutsideClick={() => setSelectRegAdress(false)}>
                <div className={style.input_wrapper}>
                  <label className={style.label}>Адрес регистрации</label>
                  <IMaskInput
                    autoComplete='off'
                    onChange={(e) => { handleChange(e); regAdressSelectSettings() }}
                    name='regAdress'
                    style={{ resize: 'none' }}
                    value={inputValues.regAdress}
                    className={style.input_medium} />
                  <p className={style.error_text}>{errors.regAdress}</p>
                  <div className={selectRegAdress ? style.custom_select_wrapper :
                    style.custom_select_wrapper_disabled}>
                    <ul className={style.select_list}>
                      {adressOptions.map((x, index) => (
                        <li onClick={() => { regAdressSelect(x.value); setSelectRegAdress(false) }} key={index} className={style.list_item}>{x.value}</li>))}
                    </ul>
                  </div>
                </div>
              </OutsideClickHandler>


            </div>

          </div>
          <div className={style.button_wrapper}>
            <Link onClick={(e) => handleSubmit(e)} to='/snils' style={{ textDecoration: 'none' }}>
              <button type='submit' className={style.button}>Далее</button>
            </Link>
            <div>
              <Link to='/passportDataForeigner' className={style.button}>Назад</Link>
            </div>
          </div>
        </form>

      </div>
    </>

  )
}
