import React, { useEffect } from 'react'
import style from '../GosKey/yourcenter.module.css'
import styles from './instruction.module.css'
import logo from '../../images/signme.svg'
import navstyle from '../../components/navbar/navbar.module.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import urls from "../../urls.json"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import QRCode from "react-qr-code";
import ModalWindow from '../../components/modal/ModalWindow'
import scanImage from "./images/scan.png"
import buttonImage from "./images/button.png"
import phoneImage from "./images/phone.png"
import successImage from "./images/success.jpg"
import codeImage from "./images/code.jpg"
export default function Instruction() {
  const [qr, setQr] = useState(localStorage.getItem("qr"))
  const [isLoader, setLoader] = useState(true)
  const [id, setId] = useState(localStorage.getItem("id"))
  const [modal, setModal] = useState(false)
  const [modalText, setModalText] = useState("")
  async function sendRequest() {
    if (qr == null) {
      setLoader(true)
      await axios.get(`${urls.url}/api/signme/request?id=${id}`)
        .then(response => {
          if(response.data==="Пользователь уже существует"){
            window.location.href="/signme/recovery"
          }else{
            setQr(response.data)
            localStorage.setItem("qr", response.data)
          }
      
        })
        .catch(error => {
          setModal(true)
          setModalText(error.response.data)
        })
    }
    setLoader(false)
  }
  async function check() {
    setLoader(true)
    await axios.get(`${urls.url}/api/signme/precheck?id=${localStorage.getItem("id")}`)
      .then(response => {
        const result = response.data
        if (result.phone && result.email && result.inn) {
          window.location.href = '/delivery'
        }
        if (result.phone && !result.email && !result.inn) {
          setModalText("Ожидаем регистрацию в приложении")
        }
        setLoader(false)
      })
  }
  useEffect(() => {
    sendRequest()
  }, [])
  return (
    <>
      <ModalWindow isActive={modal} text={modalText} onClose={() => window.location.href = `/editdata`} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item}>
          <span>6</span>
        </div>

        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>7</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Установка приложения</p>
          </div>
        </div>

      </div>
      <div className={style.container}>
        <div className={style.title_wrapper}>
          <img src={logo} />
          <h1 className={style.title}>Пройдите шаги 1-8 по установке приложения Sign.Me</h1>
        </div>
        <div className={styles.content}>
          <ol className={styles.list}>
            <li className={styles.list_item}>Ознакомьтесь с инструкцией ниже и скачайте приложение Sign.Me</li>
            <li className={styles.list_item}>Авторизуйтесь по QR коду</li>
            <img src={scanImage} />
            <img src={buttonImage} />
            <li className={styles.list_item}>Введите код в приложении в ручную: <span style={{ fontWeight: "bold", color: 'red' }}>{qr}</span></li>
            <img src={codeImage}/>
            <p className={styles.list_item}>или отсканируйте QR код: </p>
            {qr != null &&
              <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={`https://sandbox.sign.me/app/${qr}/#${qr}`}
                  viewBox={`0 0 256 256`}
                />
              </div>
            }
            <li className={styles.list_item}>Введите номер телефона указанный при регистрации и нажмите "Продолжить"</li>
            <img src={phoneImage} />
            <li className={styles.list_item}>Завершите регистрацию</li>
            <li className={styles.list_item}>Дождитесь экрана "Ожидание подтверждение личности"</li>
            <img src={successImage}></img>
            <li className={styles.list_item}>Вернитесь на страницу SignSelf и нажмите кнопку "Далее" для перехода к шагу идентификации личности через курьера</li>
            <span className={styles.list_item}>Скачайте приложение Sign.Me:</span>
            <ul>
              <li className={styles.link} onClick={() => window.open('https://apps.apple.com/ru/app/sign-me/id1502259352', '_blank')}>IOS</li>
              <li className={styles.link} onClick={() => window.open('https://play.google.com/store/apps/details?id=me.sign', '_blank')}>Android</li>
              <li className={styles.link} onClick={() => window.open('https://appgallery.huawei.com/#/app/C104232097', '_blank')}>Huawei</li>
            </ul>
          </ol>
        </div>
        <div className={styles.link_wrapper}>
          <button className={style.link} style={{ fontSize: 16 }} onClick={() => check()}>Далее</button>
        </div>
        <div className={styles.link_wrapper}>
          <Link className={style.link} style={{ backgroundColor: "red" }} to='/editdata'>Назад</Link>
        </div>
      </div>
    </>
  )
}
