import React, { useState } from "react";
import styles from "./login.module.css"
import components from "./components.module.css"
import axios from "axios";
import urls from "../../urls.json"
import { Backdrop, CircularProgress } from "@mui/material";


export default function Login(){
    const [username,setUsername]=useState("")
    const [password,setPassword]=useState("")
    const [loader,setLoader]=useState(false)
    async function Login(){
        setLoader(true)
        await axios.get(`${urls.url}/api/admin/token?password=${password}&username=${username}`)
        .then(response=>{
            localStorage.setItem('token',response.data)
            window.location.href="/admin/users"
        })
        .catch(error=>{
            alert("неверный пароль")
        })
        setLoader(false)
    }
    return(
        <React.Fragment>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    <div className={styles.box+" "+  components.border}>
        <span style={{fontSize:20,fontWeight:'bold'}}>SignSelf</span>
        <span>Логин</span>
        <input value={username} onChange={(e)=>setUsername(e.target.value)} className={components.input} type="text" />
        <span>Пароль</span>
        <input value={password} onChange={(e)=>setPassword(e.target.value)} className={components.input}  type="password" />
        <button onClick={()=>Login()} className={components.button}>ВОЙТИ</button>
    </div>
    </React.Fragment>
    )
}