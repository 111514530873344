import React from "react"
import { Link, NavLink } from "react-router-dom"
import styles from "./navbar.module.css"

export default function NavBar() {
    return (
        <div className={styles.container}>
            <NavLink className={(x) => (x.isActive ? styles.active : "")} to="/admin/employes">Пользователи</NavLink>

            <NavLink className={(x) => (x.isActive ? styles.active : "")} to="/admin/companies">Компании</NavLink>
            <NavLink className={(x) => (x.isActive ? styles.active : "")} to="/admin/users">Физ лица</NavLink>
            <NavLink className={(x) => (x.isActive ? styles.active : "")} to="/admin/choosesign">Создание ссылки</NavLink    >
            {/* <Link activeClassName={styles.active} to="/">Пользователи</Link>
        <Link  activeClassName={styles.active} to="/">Пользователи</Link>
        <Link  activeClassName={styles.active} to="/">Пользователи</Link>
        <Link  activeClassName={styles.active} to="/">Пользователи</Link> */}

        </div>)
}