// Router components
import { Routes, Route } from 'react-router-dom'
import Layout from './Layout';
// navbar
// F.A.Q page
import Faq from './pages/FAQ/Faq';
// main form
import StartForm from './pages/StartForm/StartForm';
// Passport upload pages 
import CitizenPassportUpload from './pages/СitizenPassportUpload/СitizenPassportUpload'
import ForeignerPassportUpload from './pages/ForeignerPassportUpload/ForeignerPassportUpload'
// Manual data fill pages
import ManualDataFill from './pages/ManualDataFill/ManualDataFill';
import ForeignerManualDataFill from './pages/ManualDataFill/ForeignerManualDataFill';
// Snils and INN documents pages
import Snils from './pages/SnilsINN/Snils'
import Inn from './pages/SnilsINN/Inn';
// Preloader page
import PreloaderPage from './pages/PreloadPage/PreloaderPage';
// UserForm pages
import EditData from './pages/EditData/EditData';
// Choose sign pages
// Method Choice
import MethodChoice from './pages/MethodChoice/MethodChoice';
// Center Loader
import CenterLoader from './pages/CenterLoader/CenterLoader';
// Your center 1&2
import ItMonitoring from './pages/ItMonitoring/ItMonitoring';
// Call courier
import CallCourier from './pages/CallCourier/CallCourier';
import CourierLoader from './pages/CenterLoader/CourierLoader';
// Confirmation page 
import Confirmation from './pages/Confirmation/Confirmation';
// Instruction page
import SignMe from './pages/SignMe/SignMe';
import EsiaInstruction from './pages/EsiaInstruction/EsiaInstruction';
// Download instruction
import AppDownloading from './pages/AppDownloading/AppDownloading';
// Tracking pages 
import TrackingNum from './pages/TrackingPages/TrackingNum';
import TrackingInfo from './pages/TrackingPages/TrackingInfo';
// Availability Check pages
import AvailableSign from './pages/AvailabilityСheck/AvailableSign';
import MySigns from './pages/AvailabilityСheck/MySigns';
// Contract Details page
import ContractDetails from './pages/ContractDetails/ContractDetails';
import ContractUpload from './pages/ContractUpload/ContractUpload';
import NotFound from './pages/404/NotFound';
import ItMonitoring2fa from './pages/itMonitoring2fa/ItMonitoring2fa';
import GosKey from './pages/GosKey/GosKey';
import PersonalData from './pages/personalData/PersonalData';
import Telegram from './components/telegram/Telegram';
import PassportLoader from './pages/PassportLoader/PassportLoader';
import AdminLayout from './AdminLayout';
import Login from './pages/admin/Login';
import Users from './pages/admin/Users';
import UserData from './pages/admin/UserData';
import ChooseSign from './pages/admin/ChooseSign';
import Lock from './components/lock/Lock';
import UserMiddleware from './UserMiddleware';
import Companies from './pages/admin/Companies';
import Employes from './pages/admin/Employes';
import IdPoint from './pages/idpoint/IdPoint';
import Recovery from './pages/SignMe/Recovery';
function App() {
  return (
    <>
      <Telegram />
      <Lock />
      <Routes>
        <Route path='/admin/login' element={<Login />}></Route>
        <Route component={<NotFound />} />
        <Route path='/admin' element={<AdminLayout />}>
          <Route path='/admin/companies' element={<Companies />}></Route>
          <Route path='/admin/employes' element={<Employes />}></Route>
          <Route path='/admin/users' element={<Users />}></Route>
          <Route path='/admin/users/:id' element={<UserData></UserData>}></Route>
          <Route path='/admin/choosesign' element={<ChooseSign />}></Route>

        </Route>
        <Route path='/' element={<Layout />}>
          <Route path='/signme/recovery' element={<Recovery></Recovery>}></Route>
          <Route path="/user/:id" index element={<UserMiddleware />}></Route>
          <Route path='/startform' element={<StartForm />}></Route>
          <Route path='/faq' element={<Faq />}></Route>
          <Route path='/passportupload' element={<CitizenPassportUpload />}></Route>
          <Route path='/passportDataForeigner' element={<ForeignerPassportUpload />}></Route>
          <Route path='/404' element={<NotFound />}></Route>
          <Route path='/fillData' element={<ManualDataFill />}></Route>
          <Route path='/fillDataManualyForeigner' element={<ForeignerManualDataFill />}></Route>
          <Route path='/snils' element={<Snils />}></Route>
          <Route path='/inn' element={<Inn />}></Route>
          <Route path='/preloader' element={<PreloaderPage />}></Route>
          <Route path='/editdata' element={<EditData />}></Route>
          <Route path='/methodChoice' element={<MethodChoice />}></Route>
          <Route path='/centerLoader/:center' element={<CenterLoader />}></Route>
          <Route path='/passportLoader' element={<PassportLoader />}></Route>
          <Route path='/goskey' element={<GosKey />}></Route>
          <Route path='/itmonitoring' element={<ItMonitoring />}></Route>
          <Route path='/delivery' element={<CallCourier />}></Route>
          <Route path='/courierloader' element={<CourierLoader />}></Route>
          <Route path='/confirmation' element={<Confirmation />}></Route>
          <Route path='/signme' element={<SignMe />}></Route>
          <Route path='/esiainstruction' element={<EsiaInstruction />}></Route>
          <Route path='/downloadInstruction' element={<AppDownloading />}></Route>
          <Route path='/personalData' element={<PersonalData></PersonalData>}></Route>
          <Route path='/track/:deliveryId' element={<TrackingNum />}></Route>
          <Route path='/trackInfo' element={<TrackingInfo />}></Route>
          <Route path='/itmonitoring2fa' element={<ItMonitoring2fa />}></Route>
          <Route path='/availableSign' element={<AvailableSign />}></Route>
          <Route path='/mySigns' element={<MySigns />}></Route>
          <Route path='/contractDetails' element={<ContractDetails />}></Route>
          <Route path='/contractUpload' element={<ContractUpload />}></Route>
          {/* <Route path='/idpoint' element={<IdPoint />}></Route> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
