import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./pages/admin/NavBar";
import styles from "./pages/admin/layout.module.css";
export default function AdminLayout(){
    return(
    <div className={styles.container}>
        <NavBar className={styles.navbar}></NavBar>
        <Outlet className={styles.main}></Outlet>
    </div>)
}