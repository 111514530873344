import React from 'react'
import style from './contractUpload.module.css'
import { Link } from 'react-router-dom'
import contractImg from '../../images/contract.svg'
import upload from '../../images/upload.svg'
import navstyle from '../../components/navbar/navbar.module.css'

export default function ContractUpload() {
  return (
    <>
    <div className={navstyle.navbar}>
    <div className={navstyle.navbar_item_adaptive}>
        <span>1</span>
      </div>
    <div className={navstyle.navbar_dots_adaptive}>
      <div className={navstyle.navbar_dots_item}></div>
      <div className={navstyle.navbar_dots_item}></div>
      <div className={navstyle.navbar_dots_item}></div>
    </div>
    
    <div className={navstyle.navbar_item_adaptive}>
        <span>2</span>
      </div>
      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>


      <div className={navstyle.navbar_item_adaptive}>
        <span>3</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={navstyle.navbar_item_adaptive}>
        <span>4</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={ navstyle.navbar_item}>
          <span>5</span>
        </div>
      <div className={navstyle.navbar_dots}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      
      <div className={  navstyle.item_wrapper_active}>
        <div className={ navstyle.navbar_item_active }>
          <span>6</span>
        </div>
        <div className={ navstyle.navbar_active_section  }>
          <p className={ navstyle.active_section_text} >Ввод  данных договора</p>
        </div>
        </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={ navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>
    
        </div>
<div className={style.container}>
        <div className={style.title_wrapper}>
            <img className={style.contract_img} src={contractImg} alt="" />
            <h1 className={style.title}>загрузите проект договора купли-продажи или введите данные договора вручную</h1>
        </div>

        <div className={style.inputs_container}>
            <div className={style.input_frame}>
                <label for='1'>Проект договора</label>
                <img className={style.upload_img} src={upload} alt="" />
                <div className={style.text_wrapper}>
                    <p style={{color:'#C1D3DC'}}>Перетащите сюда или выберете</p>
                    <p style={{color:'#69CD7C'}}>файлы с компьютера</p>
                    <p style={{color:'#C1D3DC'}}>Сканы или четкие фотографии. Допустимые форматы: pdf, jpg, jpeg, png. Размер файла не более 10Мб</p>
                </div>
                <input className={style.input} id='1' type="file" placeholder='0' />
            </div>
            
        </div>
        <div className={style.buttons_wrapper}>
            <div>
            <Link to='/contractdetails'>

                <button className={style.button_forward}>Далее</button>
                </Link>

            </div>
            <div>
                <Link className={style.link} to='/contractdetails'>
                <button className={style.button_handwrite}>Заполнить данные в ручную</button>
                </Link> 
            </div>
        </div>
    </div>
    </>
    
  )
}
