import React, { useState } from 'react'
import styles from './modal.module.css'
import { useEffect } from 'react';
export default function ModalWindow(props) {
   
  return (
    <div className={props.isActive ? styles.modal : styles.modal_disbled}>
        <div className={styles.modal_dialog}>
            <div className={styles.exit}>
                <p className={styles.modal_text}>
                    <span onClick={props.onClose} className={styles.close_char}>&times;</span>
                </p>
            </div>
            <p className={styles.text}>{props.text}</p>
            <div className={styles.modalBtn_wrapper}>
                <button onClick={props.onClose} className={styles.modal_button}>Закрыть</button>
            </div>
        </div>
    </div>
  )
  }
