import React from 'react'
import style from './availability.module.css'
import navstyle from '../../components/navbar/navbar.module.css'
import titleImg from '../../images/methodChoice.svg'

export default function AvailableSign() {
  return (
    <div className={style.wrapper}>
       <div className={navstyle.navbar}>
    <div className={navstyle.navbar_item_adaptive}>
        <span>2</span>
      </div>
    <div className={navstyle.navbar_dots_adaptive}>
      <div className={navstyle.navbar_dots_item}></div>
      <div className={navstyle.navbar_dots_item}></div>
      <div className={navstyle.navbar_dots_item}></div>
    </div>
    
    <div className={navstyle.navbar_item_adaptive}>
        <span>3</span>
      </div>
      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>


      <div className={navstyle.navbar_item_adaptive}>
        <span>4</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={navstyle.navbar_item_adaptive}>
        <span>5</span>
      </div>

      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={ navstyle.navbar_item_adaptive}>
          <span>6</span>
        </div>
      <div className={navstyle.navbar_dots_adaptive}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={ navstyle.navbar_item}>
          <span>7</span>
        </div>

      <div className={navstyle.navbar_dots}>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
        <div className={navstyle.navbar_dots_item}></div>
      </div>

      <div className={  navstyle.item_wrapper_active}>
        <div className={ navstyle.navbar_item_active }>
          <span>8</span>
        </div>
        <div className={ navstyle.navbar_active_section  }>
          <p className={ navstyle.active_section_text} >Установка приложения</p>
        </div>
        </div>
    
       </div>
    <div className={style.container}>
        <div className={style.title_wrapper}>
            <img className={style.title_img} src={titleImg} alt="" />
            <h1 className={style.my_title}>Ниже представлены выпущенные ранее электронные подписи</h1>
        </div>
    </div>
    
        <div className={style.table_wrapper}>
        <table>
            <thead>
                <tr>
                    <th>Номер заяки</th>
                    <th>Выпускает подпись</th>
                    <th>Выпущена</th>
                    <th>Активна до</th>
                    <th>Статус</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>99880</th>
                    <th>Такском (MyDss)</th>
                    <th>22.02.2021</th>
                    <th>22.05.2021</th>
                    <th>недейств.</th>
                    <th  className={style.buttons}><button className={style.btn}>Выпустить новую подпись</button></th>
                </tr>
                <tr>
                    <th>99880</th>
                    <th>Такском (MyDss)</th>
                    <th>22.02.2021</th>
                    <th>22.05.2021</th>
                    <th>действ.</th>
                    <th className={style.buttons}>
                        <button className={style.btn}>Печать заявления</button>
                        <button className={style.btn}>Операции</button>
                    </th>
                </tr>
            </tbody>
        </table>
        </div>
        
    </div>
  )
}
