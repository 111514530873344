import React, { useEffect } from 'react'
import { useState } from 'react'
import innImg from '../../images/inn.svg'
import style from './inn.module.css'
import { Link } from 'react-router-dom'
import { IMaskInput } from 'react-imask';
import navstyle from '../../components/navbar/navbar.module.css'
import urls from '../../urls.json'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Backdrop, CircularProgress } from '@mui/material'

export default function Inn() {
  const [inn, setInn] = useState("")
  const [errors, setErrors] = useState()
  const [isLoader, setLoader] = useState(false)
  useEffect(() => {
    getData()
  }, [])
  async function getData() {
    setLoader(true)
    await axios.get(`${urls.url}/api/idx/inn?id=${localStorage.getItem("id")}`, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      const inn = String(response.data);
      if (inn != "") {
        setInn(String(response.data))
      }
    })
    setLoader(false)
  }
  const HandleSubmit = () => {
    setLoader(true)
    let error = Validate()
    setErrors(error)
    const body = {
      "id": localStorage.getItem("id"),
      "inn": inn
    }
    axios.put(`${urls.url}/api/user/inn`, body, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        window.location.href = `/editdata`
        setLoader(false)

      })
  }


  const Validate = () => {
    let error = ''
    if (!inn) {
      error = 'обязательное поле'
    }
    if (inn.length < 12) {
      error = "неверный формат"
    }
    return error;
  }
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>4</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Ввод данных ИНН</p>
          </div>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>6</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>7</span>
        </div>

      </div>
      <div className={style.container}>
        <div className={style.title_wrapper}>
          <img src={innImg} className={style.img} alt="" />
          <div className={style.inn_input_wrapper}>
            <p className={style.input_text}>Введите ваш ИНН</p>
            <div className={style.input_wrapper}>
              <label className={style.label}>ИНН</label>
              <IMaskInput
                autoComplete='off'
                mask="000000000000"
                onAccept={(value) => setInn(value)}
                value={inn}
                className={style.input} />
              <p className={style.error_text}>{errors}</p>
            </div>
          </div>
        </div>
        <div className={style.inn_text_wrapper}>
          <p className={style.inn_text}>Если вы не помните свой ИНН, а свидетельства нет на руках, узнайте номер <a className={style.fns_link} target='_blank' href="https://service.nalog.ru/inn.do">на сайте ФНС</a> и скопируйте его в поле ниже.</p>
        </div>
        <div className={style.button_wrapper} style={{ marginBottom: '20px' }}>
          <button onClick={() => HandleSubmit()} className={style.button}>Далее</button>
        </div>
        <div className={style.button_wrapper} >
          <Link to='/snils' style={{ backgroundColor: "red" }} className={style.button}>Назад</Link>
        </div>
      </div>
    </>

  )
}
