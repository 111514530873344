import React, { useEffect, useState } from "react"
import styles from "./users.module.css"
import PropTypes from 'prop-types';
import icon from "./images/icon.svg"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from "react-router-dom";
import axios from "axios";
import components from "./components.module.css";
import urls from "../../urls.json";
import itmonitoring from "./images/itmonitoring.svg"
import signme from "./images/signme.png"
import { Backdrop, Box, CircularProgress, Modal } from "@mui/material";
import ModalWindow from "../../components/modal/ModalWindow";
const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    border: 'none!important',
    justifyContent: "center",
    flexDirection: "column",
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
    gap: 2
  };
export default function Companies() {
    const [rows, setRows] = useState([])
    const [isLoader, setLoader] = useState(false)
    const [modal, setModal] = useState(false)
    useEffect(() => GetData(), [])


    async function GetData() {
        setLoader(true)
        await axios.get(`${urls.url}/api/admin/companies`, { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } })
            .then(response => {
                setRows(response.data)
            })
            .catch(error => {
                window.location.href = "/admin/login"
            })
        setLoader(false)
    }

    return (
        <div className={components.container}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style1}>
                    <span>в разработке</span>
                </Box>
            </Modal>
            <Table stickyHeader aria-label="collapsible table" >
                <TableHead>
                    <TableRow>
                        {/* <TableCell /> */}
                        <TableCell align="center">Название</TableCell>
                        <TableCell align="center"></TableCell>


                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                                <button onClick={() => setModal(true)} className={components.button}>Ключи</button>
                            </TableCell>
                        </TableRow>

                    ))}
                </TableBody>
            </Table>
        </div>)
}
