import React from 'react'
import style from './availability.module.css'
import signmeImg from '../../images/signme.svg'
import idpointImg from '../../images/idpointDark.svg'
import myddsImg from '../../images/mydds.svg'
export default function MySigns() {
  return (
    <div className={style.container}>
        <h1 className={style.title}>У вас есть ранее выпущенная электронная подпись</h1>
        <div className={style.signs}>
            <div className={style.sign}>
                <img className={style.sign_img}  src={signmeImg} alt="" />
                <span className={style.sign_name}>Sign.me</span>
            </div>
            <div className={style.sign}>
                <img className={style.sign_img} src={idpointImg} alt="" />
                <span className={style.sign_name}>IDPOINT</span>
            </div>
            <div className={style.sign}>
                <img className={style.sign_img} src={myddsImg} alt="" />
                <span className={style.sign_name}>MyDss</span>
            </div>
        </div>
    </div>
  )
}
