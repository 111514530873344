
import { BrowserRouter } from 'react-router-dom';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';


ReactDOM.render(
<BrowserRouter>
      <React.StrictMode>
        <Suspense fallback={<div>...</div>}>
          <App/>
        </Suspense>
      </React.StrictMode>
</BrowserRouter>,
document.getElementById('root')
);