import React, { useState } from 'react'
import { useEffect } from 'react'
import style from '../../pages/PreloadPage/preloader.module.css'
import Moonloader from 'react-spinners/MoonLoader'
import navstyle from '../../components/navbar/navbar.module.css'
import { useParams } from 'react-router-dom'
import urls from "../../urls.json"
import axios from 'axios'
import ModalWindow from '../../components/modal/ModalWindow';

export default function CenterLoader() {
  const { center } = useParams()
  const [modal, setModal] = useState(false)
  const [modalText, setModalText] = useState(false)
  const [errorLink, setErrorLink] = useState(null)
  useEffect(() => {
    Move()
  }, [])

  async function Move() {
    switch (center) {
      case "goskey":
        window.location.href = "/goskey"
        break;
      case "idpoint":
        await axios.get(`${urls.url}/api/idpoint/request?id=${localStorage.getItem("id")}`)
          .then(response => {
            window.location.href = "/idpoint"
          })
          .catch(error => {
            setModal(true)
            setModalText(error.response.data)
            setErrorLink("/editdata")
          })
        break;
      case "itmonitoring":
        await axios.get(`${urls.url}/api/itmonitoring/request?id=${localStorage.getItem("id")}`)
          .then(response => {
            window.location.href = "/itmonitoring"

          })
          .catch(error => {
            setModal(true)
            setModalText(error.response.data)
            setErrorLink("/editdata")
          })
        break;
      case "signme":
        await axios.get(`${urls.url}/api/signme/precheck?id=${localStorage.getItem("id")}`)
          .then(response => {
            const result = response.data;
            if (result.approved) {
              localStorage.setItem("pdf",result.pdf)
              window.location.href = "/signme/recovery"
            }
            else {
              if (result.phone && result.email && result.inn) {
                window.location.href = "/delivery"
              }
              else {
                window.location.href = "/signme"
              }
            }
          })
          .catch(error => {
            window.location.href = "/availableSign"
          })
        break;
      case "tochka":
        await axios.get(`${urls.url}/api/tochka/create?id=${localStorage.getItem("id")}`).then(response => {
          window.location.href = "/delivery"
        }).catch(error => {
          setModal(true)
        })
        break;
    }
  }
  return (
    <>
      <ModalWindow isActive={modal} text={modalText} onClose={() => errorLink != null ? window.location.href = errorLink : "/editdata"} />
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.navbar_item}>
          <span>6</span>
        </div>
        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>
        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>7</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Определение удостоверяющего центра </p>
          </div>
        </div>
      </div>
      <div className={style.container}>
        <div className={style.loader_container}>
          <Moonloader className={style.loader} color="#69CD7C" size={160} speedMultiplier={0.5}></Moonloader>
          <span className={style.loader_text}>Минуточку...</span>
        </div>
        <div className={style.text_wrapper}>
          <h2 className={style.title}>ОПРЕДЕЛЯЕМ УДОСТОВЕРЯЮЩИЙ ЦЕНТР</h2>
          <p className={style.subtitle}>Эта процедура занимает не более <span style={{ color: '#69CD7C' }}> минуты</span>. Когда получим подтверждение, на экране появятся следующие шаги для выпуска электронной подписи.</p>
          <p className={style.subtitle}>
            Когда удостоверяющий центр будет определен, вам придет <span style={{ color: '#69CD7C' }}>СМС</span> о том, что можно продолжать оформление.</p>
        </div>
      </div>
    </>

  )
}
