import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import style from './yourcenter.module.css'
import goskeyImg from '../../images/goskey.png'
import qrImg from './img/qrcode.jpeg'
import navstyle from '../../components/navbar/navbar.module.css'
import axios from 'axios'
import urls from "../../urls.json"

export default function GosKey() {
  useEffect(()=>{
    axios.get(`${urls.url}/api/user/goskey?id=${localStorage.getItem('id')}`)
  },[])
  return (
    <>
      <div className={navstyle.navbar}>
        <div className={navstyle.navbar_item_adaptive}>
          <span>1</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>2</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>


        <div className={navstyle.navbar_item_adaptive}>
          <span>3</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>4</span>
        </div>

        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item_adaptive}>
          <span>5</span>
        </div>
        <div className={navstyle.navbar_dots_adaptive}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.navbar_item}>
          <span>6</span>
        </div>

        <div className={navstyle.navbar_dots}>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
          <div className={navstyle.navbar_dots_item}></div>
        </div>

        <div className={navstyle.item_wrapper_active}>
          <div className={navstyle.navbar_item_active}>
            <span>7</span>
          </div>
          <div className={navstyle.navbar_active_section}>
            <p className={navstyle.active_section_text} >Определение удостоверяющего центра </p>
          </div>
        </div>

      </div>
      <div className={style.container}>
        <div className={style.title_wrapper}>
          <img className={style.title_img} src={goskeyImg} alt="" />
          <h1 className={style.title}>ВЫПУСК УКЭП В ГОСКЛЮЧЕ</h1>
        </div>
        <div className={style.yourcenter_content_wrapper}>
          <div className={style.qr_text_wrapper}>
            <p className={style.yourcenter_text}>Отсканируйте QR - код или перейдите по
              <a className={style.active_text} href="https://goskey.ru/">ссылке</a> для установки приложения Госключ. Далее следуйте
              <span className={style.active_text}>инструкции</span> по выпуску подписи.</p>
            <img src={qrImg} style={{ width: "20%" }} alt="" />
          </div>
          <p className={style.support_text}>При возникновении вопросов или сложностей напишите в службу<span className={style.active_text}>поддержки в Телеграм. </span>Так же можно обратиться в соответствующий раздел<Link to='/faq' className={style.active_text}>F.A.Q</Link></p>
        </div>
        <div className={style.button_wrapper}>
          <Link className={style.link} style={{backgroundColor:"red"}} to={`/startform`}>Назад</Link>
        </div>
      </div>
    </>

  )
}
